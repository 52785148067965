import React from 'react';
import { Accordion, Segment } from 'semantic-ui-react';

const DogCareDetails = () => {
    const panels = [
        {
            key: 'veterinary-exam',
            title: 'Exame Veterinário Inicial',
            content: {
                content: (
                    <div>
                        <p>Ao adquirir um novo cão: Leve ao veterinário para um check-up inicial. Isso pode incluir exames físicos, testes para doenças infecciosas e conselhos sobre cuidados adequados.</p>
                    </div>
                ),
            },
        },
        {
            key: 'vaccinations-puppies',
            title: 'Vacinações Iniciais (Filhotes)',
            content: {
                content: (
                    <div>
                        <p>8 semanas: Primeira dose da vacina contra Tosse dos Canis (se necessário).</p>
                        <p>6 a 8 semanas: Primeira dose da DHLPP.</p>
                        <p>10 a 12 semanas: Segunda dose da DHLPP.</p>
                        <p>12 a 16 semanas: Vacina antirrábica.</p>
                        <p>14 a 16 semanas: Terceira dose da DHLPP.</p>
                        <p>6 meses: Primeira dose da vacina contra Leishmaniose.</p>
                        <p>1 ano: Primeira dose da vacina contra Dirofilariose.</p>
                    </div>
                ),
            },
        },
        {
            key: 'vaccinations-adults',
            title: 'Vacinações Anuais (Adultos)',
            content: {
                content: (
                    <div>
                        <p>Anualmente: Reforço da DHLPP.</p>
                        <p>Anualmente: Reforço da vacina antirrábica (conforme recomendação veterinária, pode ser a cada três anos).</p>
                        <p>Anualmente: Reforço da vacina contra Leishmaniose.</p>
                        <p>Anualmente: Reforço da vacina contra Dirofilariose.</p>
                        <p>Anualmente: Reforço da vacina contra Tosse dos Canis (dependendo do risco de exposição).</p>
                    </div>
                ),
            },
        },
        {
            key: 'parasite-prevention',
            title: 'Prevenção de Parasitas',
            content: {
                content: (
                    <div>
                        <p>Interna: Uma vez por mês até atingir os 6 meses, depois passa a ser uma vez a cada 3 meses.</p>
                        <p>Externa: Coleira de proteção dura 1 ano.</p>
                        <p>Externa: Nos meses de verão pode-se reforçar com comprimidos ou ampôlas.</p>
                    </div>
                ),
            },
        },
        {
            key: 'chip-and-sterilization',
            title: 'Colocação de Chip e Esterilização',
            content: {
                content: (
                    <div>
                        <p>Colocação de Chip: Ao ser vacinado ou durante a primeira consulta veterinária mas normalmente acontece depois da terceira vacina da DHLPP.</p>
                        <p>Esterilização (Castração ou Ovariectomia): 6 a 9 meses, dependendo do tamanho da raça e das recomendações do veterinário.</p>
                    </div>
                ),
            },
        }
    ];

    return (
        <Segment basic>
            <Accordion panels={panels} styled fluid />
        </Segment>
    );
};

export default DogCareDetails;
