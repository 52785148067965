import React, { useEffect, useState } from 'react';
import { Button, Header, Icon, List, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import CreationModals from './modals/CreationModals';
import VeterinarianForm from './forms/VeterinarianForm';
import { vetsCollection } from '../../resources/const/firebaseCollections';
import { notifyError, notifySuccess } from '../../resources/lib';
import { deleteDocById } from '../../resources/firebaseLib';
import ConfimationModal from '../modals/ConfimationModal';

const VeterinarianList = ({ pet, veterinarians, reload }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleModal = async (value, item) => {
        if (value) {
            const result = deleteDocById(vetsCollection, item.id);
            if (result) {
                notifySuccess(`Veterinário apagada com sucesso.`);
            }
            else {
                notifyError(`Falha ao apagar Veterinário.`);
            }
            reload(pet);
        }
    };

    return (
        <>
            <Header as="h5">Lista de Veterinários</Header>
            {veterinarians.length === 0 ? (
                <Segment>Nenhum veterinário adicionado.</Segment>
            ) : (
                <List divided relaxed style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    {veterinarians.map((vet, index) => (
                        <List.Item key={index}>
                            {windowWidth > 758 &&
                                <List.Icon name='doctor' size='large' verticalAlign='middle' />
                            }
                            <List.Content>
                                <CreationModals
                                    title='Atualizar Veterinário'
                                    trigger={<List.Header as={Link}>{vet.name}</List.Header>}
                                >
                                    <VeterinarianForm pet={pet} veterinarian={vet} reload={reload} />
                                </CreationModals>
                                {windowWidth > 758 &&
                                    <List.Content floated='right'>
                                        <ConfimationModal
                                            title='Tem certeza de que deseja apagar esta veterinário?'
                                            trigger={<Button inverted color='red'><Icon name='trash' />Apagar</Button>}
                                            action={handleModal}
                                            item={vet} />
                                    </List.Content>
                                }
                                <List.Description>{`${vet.clinicName}, ${vet.city}`}</List.Description>
                                <List.Description>{vet.contact}</List.Description>
                                <List.Description>{vet.email}</List.Description>
                                {windowWidth <= 758 &&
                                    <List.Description>
                                        <ConfimationModal
                                            title='Tem certeza de que deseja apagar esta veterinário?'
                                            trigger={<Button inverted color='red'><Icon name='trash' />Apagar</Button>}
                                            action={handleModal}
                                            item={vet} />
                                    </List.Description>
                                }
                            </List.Content>
                        </List.Item>
                    ))}
                </List>
            )}
            <CreationModals
                title='Adicionar Veterinário'
                trigger={<Button
                    icon='add'
                    content='Adicionar Veterinário'
                    className='primary-color'
                />}
            >
                <VeterinarianForm pet={pet} reload={reload} />
            </CreationModals>
        </>
    );
};

export default VeterinarianList;