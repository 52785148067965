import React from 'react';
import { Step, Segment } from 'semantic-ui-react';

const HowItWorks = () => {
  return (
    <Segment basic vertical style={{ display: 'flex', justifyContent: 'center' }}>
      <Step.Group size='mini' style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <Step completed className="step-background" icon='signup' title='Cadastre-se' description='Crie sua conta no myPET.ID facilmente.' />
        <Step completed className="step-background" icon='add user' title='Adicione seu Pet' description='Insira as informações do seu pet e mantenha tudo atualizado.' />
        <Step className="step-background" icon='connectdevelop' title='Conecte-se' description='Explore a comunidade e conecte-se com outros pets e donos.' />
      </Step.Group>
    </Segment>
  );
};

export default HowItWorks;
