import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Form, Input, Segment } from 'semantic-ui-react';
import { sendPasswordResetEmail } from 'firebase/auth'
import { LogErrors, notifyError, notifySuccess } from '../../resources/lib';
import { auth } from '../../firebase';
import ReCAPTCHA from 'react-google-recaptcha';

const ResetPasswordForm = () => {
  const [formData, setFormData] = useState({
    email: ''
  });
  const [isVerified, setIsVerified] = useState(process.env.REACT_APP_ENV === 'dev');
  const navigate = useNavigate();

  const handleChange = (e, { name, value }) => setFormData({ ...formData, [name]: value });

  const validObject = () => {
    return (formData.email.trim() !== '' &&
      isVerified);
  }

  const resetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      return true;
    } catch (error) {
      LogErrors(error);
      return false;
    }
  };

  const handleSubmit = async () => {
    const result = resetPassword(formData.email);
    if (result) {
      notifySuccess('A sua senha foi reposta com sucesso.');
      navigate('/');
    } else {
      notifyError('Falha a redefinir a senha.');
    }
  };

  return (
    <Container style={{ marginTop: '2em', marginBottom: '2em' }}>
      <Segment basic>
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            <Input
              label='Email'
              placeholder='Endereço de email'
              name='email'
              type='email'
              value={formData.email}
              onChange={handleChange}
            />
          </Form.Field>
          {process.env.REACT_APP_ENV !== 'dev' &&
            <Form.Field>
              <div className='center-contents'>
                <ReCAPTCHA size='normal' sitekey='6Lc3bqopAAAAAK7LTmTMUUIt2DxubgzS2i5ZOV9l' onChange={() => setIsVerified(true)} onExpired={() => setIsVerified(false)} />
              </div>
            </Form.Field>
          }
          <Button fluid type='submit' color='green' className="reset-form-button" style={{ width: '70%', margin: 'auto' }} disabled={!validObject()}>Redefinir Senha</Button>
        </Form>
      </Segment>
    </Container>
  );
};

export default ResetPasswordForm;
