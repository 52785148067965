import React, { useEffect, useState } from 'react';
import { Form, Button, Select, Checkbox, Input } from 'semantic-ui-react';
import { LogErrors, getCurrentDateTime, notifyError, notifySuccess, removeId } from '../../../resources/lib';
import { vaccinesCollection } from '../../../resources/const/firebaseCollections';
import { addDataToCollection, updateDataByDocId } from '../../../resources/firebaseLib';

const VaccineForm = ({ pet, reload, vaccine, veterinarians, closeModal }) => {
  const [formData, setFormData] = useState(vaccine ? vaccine : {
    name: '',
    purpose: '',
    otherPurpose: '',
    givenDate: '',
    nextDate: '',
    doctorName: ''
  });
  const [purposeList, setPurposeList] = useState([]);

  useEffect(() => {
    const list = formData.purpose ? formData.purpose.split(', ') : [];
    setPurposeList(list);
  }, [formData.purpose]);

  const isUpdate = vaccine ? true : false;

  const vaccinationOptions = [
    "Antirrábica", "Esgana", "Hepatite", "Parvovirose", "Leptospirose",
    "Parainfluenza", "Bordetelose", "Babesiose", "Leishmaniose", "Dirofilariose"
  ];

  const formatVeterinarians = (veterinarians) => {
    let vetOptions = [];
    let counter = 0;
    veterinarians.forEach(vet => {
      vetOptions.push({
        key: counter,
        text: vet.name,
        value: vet.id
      });
      counter++;
    });
    return vetOptions;
  }

  const handleChange = (e, { name, value }) => setFormData({ ...formData, [name]: value });

  const handleCheckboxChange = (e, { value, checked }) => {
    const newPurpose = checked
      ? [...purposeList, value]
      : purposeList.filter(purpose => purpose !== value);

    setPurposeList(newPurpose);
  };

  const handleOtherPurposeChange = (e, { value }) => {
    setFormData({ ...formData, otherPurpose: value.trim() });
  };

  async function populateCollection(data, uid) {
    try {
      const addData = {
        uid: uid,
        name: data.name.trim(),
        purpose: data.purpose.trim(),
        otherPurpose: data.otherPurpose.trim(),
        givenDate: data.givenDate,
        nextDate: data.nextDate,
        doctorName: data.doctorName,
        creationDate: getCurrentDateTime()
      };

      return await addDataToCollection(vaccinesCollection, addData);
    } catch (error) {
      LogErrors(error);
      return false;
    }
  };

  const validObject = () => {
    return (formData.name.trim() !== '' &&
      purposeList.length > 0 &&
      formData.givenDate !== '' &&
      formData.nextDate !== '' &&
      formData.doctorName !== '');
  }

  const handleSubmit = async () => {
    formData.purpose = purposeList.sort().join(', ');
    formData.otherPurpose = !purposeList.includes('Other') ? '' : formData.otherPurpose;
    if (!isUpdate) {
      const result = await populateCollection(formData, pet.id);
      if (result) {
        notifySuccess('Vacina adicionada com sucesso.');
        reload(pet);
      }
      else {
        notifyError('Falha ao criar a sua Vacina.');
      }
    }
    else {
      const result = await updateDataByDocId(vaccinesCollection, formData.id, removeId(formData));
      if (result) {
        notifySuccess('Vacina atualizado com sucesso.');
        reload(pet);
        closeModal();
      }
      else {
        notifyError('Falha ao atualizar o seu Vacina.');
        closeModal();
      }
    }
    closeModal(true);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Input
        label='Nome da Vacina'
        placeholder='Nome'
        name='name'
        value={formData.name}
        onChange={handleChange}
        required
      />
      <Form.Field required>
          <label>Propósito</label>
        </Form.Field>
      {vaccinationOptions.sort().map((option, index) => (
        <Form.Field key={index}>
          <Checkbox
            label={option}
            value={option}
            checked={purposeList.includes(option)}
            onChange={handleCheckboxChange}
          />
        </Form.Field>
      ))}
      <Form.Field>
        <Checkbox
          label="Outro"
          value="Other"
          checked={purposeList.includes('Other')}
          onChange={handleCheckboxChange}
        />
        {purposeList.includes('Other') && (
          <Input
            placeholder="Especifique se quiser..."
            name="otherPurpose"
            value={formData.otherPurpose}
            onChange={handleOtherPurposeChange}
          />
        )}
      </Form.Field>
      <Form.Input
        label='Data da Vacinação'
        type='date'
        name='givenDate'
        value={formData.givenDate}
        onChange={handleChange}
        required
      />
      <Form.Input
        label='Próxima Data de Vacinação'
        type='date'
        name='nextDate'
        value={formData.nextDate}
        onChange={handleChange}
        required
      />
      {
        veterinarians.length > 0 &&
        <Form.Input
          control={Select}
          label='Nome do Médico'
          options={formatVeterinarians(veterinarians)}
          placeholder='Selecione o médico'
          name='doctorName'
          value={formData.doctorName}
          onChange={handleChange}
          required
        />
      }
      {
        veterinarians.length === 0 &&
        <Form.Field>
          <label>Adiciona um Veterinário primeiro.</label>
        </Form.Field>
      }
      < Button type='submit' className='primary-color' disabled={!validObject()}>Gravar</Button>
    </Form >
  );
};

export default VaccineForm;
