import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Container, Form, Input, Icon, Segment, Modal, ModalActions } from 'semantic-ui-react';
import { useAuth } from './AuthContext';
import { signInWithEmailAndPassword } from 'firebase/auth'
import { LogErrors, notifyError, notifySuccess } from '../../resources/lib';
import { auth } from '../../firebase';
import { getDataBy2Wheres, isLogged } from '../../resources/firebaseLib';
import { usersCollection } from '../../resources/const/firebaseCollections';
import ReCAPTCHA from 'react-google-recaptcha';
import ResetPasswordForm from './ResetPassword';

const LoginForm = () => {
  const [open, setOpen] = useState(false)
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isVerified, setIsVerified] = useState(process.env.REACT_APP_ENV === 'dev');
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleChange = (e, { name, value }) => setFormData({ ...formData, [name]: value });

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const validObject = () => {
    return (formData.email.trim() !== '' &&
      formData.password.trim() !== '' &&
      isVerified);
  }

  async function loginAccount(data) {
    try {
      await signInWithEmailAndPassword(auth, data.email, data.password);
      return true;
    } catch (error) {
      LogErrors(error);
      return false;
    }
  };

  const handleSubmit = async () => {
    if (isVerified) {
      let result = await loginAccount(formData);
      if (result) {
        if (isLogged(auth)) {
          const userData = await getDataBy2Wheres(usersCollection, 'uid', 'isDelete', auth.currentUser.uid, 0);
          if (userData.length > 0) {
            login({
              uid: userData[0].uid,
              name: userData[0].name,
              email: userData[0].email,
              photo: 'icon'
            });
            notifySuccess('Entrou na sua conta com sucesso.');
            navigate('/');
          }
          else {
            notifyError('A sua conta não existe ou irá ser apagada.');
          }
        }
        else {
          notifyError('Falha de autenticação no Firebase, contacte o suporte.');
        }
      }
      else {
        notifyError('Falha a entrar na sua conta.');
      }
    } else {
      notifyError('Por favor, confirme que não é um robô.');
    }
  };

  return (
    <Container style={{ marginTop: '2em', marginBottom: '2em' }}>
      <Segment basic>
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            <Input
              label='Email'
              placeholder='Endereço de email'
              name='email'
              type='email'
              value={formData.email}
              onChange={handleChange}
              autoComplete="username"
            />
          </Form.Field>
          <Form.Field>
            <Input
              label='Senha'
              placeholder='Senha'
              name='password'
              type={showPassword ? 'text' : 'password'}
              value={formData.password}
              onChange={handleChange}
              icon={
                <Icon name={showPassword ? 'eye slash outline' : 'eye'} link onClick={toggleShowPassword} />
              }
              autoComplete="current-password"
            />
          </Form.Field>
          <Form.Field>
            <Modal
              trigger={<Link>Esqueceu sua senha?</Link>}
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              open={open}
              size='tiny'
            >
              <Modal.Header className='primary-color'>Redefinir Senha</Modal.Header>
              <Modal.Content>
                <ResetPasswordForm />
              </Modal.Content>
              <ModalActions className='tertiary-color'>
                <Button negative onClick={() => setOpen(false)}>
                  Fechar
                </Button>
              </ModalActions>
            </Modal>
          </Form.Field>
          {process.env.REACT_APP_ENV !== 'dev' &&
            <Form.Field>
              <div className='center-contents'>
                <ReCAPTCHA size='normal' sitekey='6Lc3bqopAAAAAK7LTmTMUUIt2DxubgzS2i5ZOV9l' onChange={() => setIsVerified(true)} onExpired={() => setIsVerified(false)} />
              </div>
            </Form.Field>
          }
          <Button fluid type='submit' color='green' className="register-form-button" style={{ width: '70%', margin: 'auto' }} disabled={!validObject()}>Entrar</Button>
        </Form>
      </Segment>
    </Container>
  );
};

export default LoginForm;
