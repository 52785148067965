import React from 'react';
import { Accordion, Segment } from 'semantic-ui-react';

const RodentCareDetails = () => {
    const panels = [
        {
            key: 'veterinary-exam',
            title: 'Exame Veterinário Inicial',
            content: {
                content: (
                    <div>
                        <p>Ao adquirir um novo roedor: É recomendável realizar um exame inicial com um veterinário que tenha experiência com pequenos mamíferos. Isso pode incluir uma avaliação de saúde geral e orientações sobre cuidados adequados.</p>
                    </div>
                ),
            },
        },
        {
            key: 'regular-checkups',
            title: 'Check-ups Regulares',
            content: {
                content: (
                    <div>
                        <p>Anualmente: Visitas anuais ao veterinário são importantes para monitorar a saúde do seu roedor e detectar precocemente possíveis problemas de saúde.</p>
                    </div>
                ),
            },
        },
        {
            key: 'nutrition',
            title: 'Nutrição Adequada',
            content: {
                content: (
                    <div>
                        <p>Dieta específica para a espécie: Alimente seu roedor com uma dieta formulada especialmente para sua espécie, que geralmente inclui uma combinação de pelotas comerciais, feno fresco (especialmente para porquinhos-da-índia e chinchilas), vegetais frescos e uma quantidade limitada de frutas.</p>
                        <p>Água fresca: Disponibilize sempre água fresca e limpa.</p>
                    </div>
                ),
            },
        },
        {
            key: 'environment-maintenance',
            title: 'Manutenção do Ambiente',
            content: {
                content: (
                    <div>
                        <p>Limpeza diária: Limpe a gaiola regularmente para evitar o acúmulo de fezes e urina, o que pode contribuir para problemas de saúde.</p>
                        <p>Ambiente enriquecido: Forneça uma gaiola espaçosa com material para ninho, brinquedos e oportunidades para escavação ou escalada, conforme apropriado para a espécie.</p>
                    </div>
                ),
            },
        },
        {
            key: 'parasite-prevention',
            title: 'Prevenção de Parasitas',
            content: {
                content: (
                    <div>
                        <p>Consultas regulares ao veterinário: Pergunte ao seu veterinário sobre a necessidade de tratamentos preventivos contra parasitas internos e externos.</p>
                    </div>
                ),
            },
        },
        {
            key: 'specific-care-guinea-pigs',
            title: 'Específicos para Porquinhos-da-Índia',
            content: {
                content: (
                    <div>
                        <p>Suplementação de Vitamina C: Porquinhos-da-índia não produzem vitamina C e precisam de suplementação diária, seja através de dieta rica em vitamina C ou suplementos.</p>
                    </div>
                ),
            },
        },
        {
            key: 'handling',
            title: 'Socialização e Manipulação',
            content: {
                content: (
                    <div>
                        <p>Manipulação cuidadosa: Roedores podem ser tímidos e requerem manipulação cuidadosa e regular para se acostumarem com o contato humano. Isso é especialmente importante para desenvolver um vínculo com seu pet.</p>
                    </div>
                ),
            },
        }
    ];

    return (
        <Segment basic>
            <Accordion panels={panels} styled fluid />
        </Segment>
    );
};

export default RodentCareDetails;
