import React, { useCallback, useEffect, useState } from 'react';
import { Tab, Divider, Container, Segment, Dimmer, Loader } from 'semantic-ui-react';
import { LogErrors, notifyError, sortObjectsByProperty } from '../../resources/lib';
import { birdsCollection, catsCollection, chipsCollection, dewormingsCollection, dogsCollection, measurementsCollection, medicationsCollection, reptilesCollection, rodentsCollection, surgeriesCollection, vaccinesCollection, vetsCollection } from '../../resources/const/firebaseCollections';
import { getDataBy, getDocById } from '../../resources/firebaseLib';
import VaccinationList from '../vet/VaccinationList';
import DewormingList from '../vet/DewormingList';
import MedicationList from '../vet/MedicationList'
import SurgeryList from '../vet/SurgeryList';
import MeasurementsList from '../vet/MeasurementsList';
import ChipInfo from '../vet/views/ChipInfo';
import ResponsiveTab from '../global/ResponsiveTab'
import DogProfilePreview from '../vet/views/animals/DogProfilePreview';
import CatProfilePreview from '../vet/views/animals/CatProfilePreview';
import BirdProfilePreview from '../vet/views/animals/BirdProfilePreview';
import RodentProfilePreview from '../vet/views/animals/RodentProfilePreview';
import ReptileProfilePreview from '../vet/views/animals/ReptileProfilePreview';
import { useParams } from 'react-router-dom';
import { getAllFilesFromFolder } from '../../resources/storageLib';

const PetProfilePage = () => {
    const { type, id } = useParams();
    const [onLoad, setOnLoad] = useState(false);
    const [pet, setPet] = useState(null);
    const [veterinarians, setVeterinarians] = useState([]);
    const [vaccines, setVaccines] = useState([]);
    const [dewormings, setDewormings] = useState([]);
    const [medications, setMedications] = useState([]);
    const [surgeries, setSurgeries] = useState([]);
    const [measurements, setMeasurements] = useState([]);
    const [chip, setChip] = useState(null);
    const [petPhoto, setPetPhoto] = useState(null);

    const getCollection = useCallback((type) => {
        switch (type) {
            case 'cat': return catsCollection;
            case 'bird': return birdsCollection;
            case 'rodent': return rodentsCollection;
            case 'reptile': return reptilesCollection;
            default: return dogsCollection;
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setOnLoad(true);
            try {

                const collection = getCollection(type);
                const petData = await getPet(id, collection);
                setPet(petData);
                await getVets(petData);
                await getVaccines(petData);
                await getDewormings(petData);
                await getMedications(petData);
                await getSurgeries(petData);
                await getMeasurements(petData);
                await getChip(petData);
                await getPetPhotos(petData);
            } catch (error) {
                LogErrors(error);
                notifyError("Falha a carregar os dados do pet.");
            }
            setOnLoad(false);
        };

        if (type && id) {
            fetchData();
        }
    }, [type, id, getCollection]);

    async function getPet(id, collection) {
        return await getDocById(collection, id);
    };

    async function getVets(pet) {
        const data = await getDataBy(vetsCollection, 'uid', pet.id);
        setVeterinarians(sortObjectsByProperty(data, 'creationDate', 'desc'));
    };

    async function getVaccines(pet) {
        const data = await getDataBy(vaccinesCollection, 'uid', pet.id);
        setVaccines(sortObjectsByProperty(data, 'givenDate', 'desc'));
    };

    async function getDewormings(pet) {
        const data = await getDataBy(dewormingsCollection, 'uid', pet.id);
        setDewormings(sortObjectsByProperty(data, 'creationDate', 'desc'));
    };

    async function getMedications(pet) {
        const data = await getDataBy(medicationsCollection, 'uid', pet.id);
        setMedications(sortObjectsByProperty(data, 'creationDate', 'desc'));
    };

    async function getSurgeries(pet) {
        const data = await getDataBy(surgeriesCollection, 'uid', pet.id);
        setSurgeries(sortObjectsByProperty(data, 'creationDate', 'desc'));
    };

    async function getMeasurements(pet) {
        const data = await getDataBy(measurementsCollection, 'uid', pet.id);
        setMeasurements(sortObjectsByProperty(data, 'creationDate', 'desc'));
    };

    async function getChip(pet) {
        const data = await getDataBy(chipsCollection, 'uid', pet.id);
        if (data.length > 0)
            setChip(data[0]);
        else
            setChip(null);
    };

    async function getPetPhotos(pet) {
        const data = await getAllFilesFromFolder(`photos/${pet.id}`);
        if (data.length > 0)
            setPetPhoto(data[0].url);
    };

    if (pet === null)
        return null;

    const renderProfile = (pet) => {
        switch (pet.type) {
            case 'cat': return <CatProfilePreview pet={pet} petPhoto={petPhoto} measurements={measurements} />;
            case 'bird': return <BirdProfilePreview pet={pet} petPhoto={petPhoto} measurements={measurements} />;
            case 'rodent': return <RodentProfilePreview pet={pet} petPhoto={petPhoto} measurements={measurements} />;
            case 'reptile': return <ReptileProfilePreview pet={pet} petPhoto={petPhoto} measurements={measurements} />;
            default: return <DogProfilePreview pet={pet} petPhoto={petPhoto} measurements={measurements} />;
        }
    };

    // Tab panes estrutura
    const panes = [
        { menuItem: 'Vacinação', render: () => <Tab.Pane><div><VaccinationList pet={pet} vaccines={vaccines} veterinarians={veterinarians} isView={true} reload={() => { }} /></div></Tab.Pane> },
        { menuItem: 'Desparasitação', render: () => <Tab.Pane><div><DewormingList pet={pet} dewormings={dewormings} isView={true} reload={() => { }} /></div></Tab.Pane> },
        { menuItem: 'Medicação', render: () => <Tab.Pane><div><MedicationList pet={pet} medications={medications} veterinarians={veterinarians} isView={true} reload={() => { }} /></div></Tab.Pane> },
        { menuItem: 'Cirurgias', render: () => <Tab.Pane><div><SurgeryList pet={pet} surgeries={surgeries} veterinarians={veterinarians} isView={true} reload={() => { }} /></div></Tab.Pane> },
        { menuItem: 'Pesagens', render: () => <Tab.Pane><div><MeasurementsList pet={pet} measurements={measurements} isView={true} reload={() => { }} /></div></Tab.Pane> },
        pet.type === 'dog' || pet.type === 'cat' ? { menuItem: 'Chip', render: () => <Tab.Pane><div><ChipInfo chipInfo={chip} /></div></Tab.Pane> } : <></>
    ];

    return (
        <Container fluid>
            <br />
            {onLoad &&
                <Segment basic style={{ minHeight: '66vh' }}>
                    <Dimmer active style={{ backgroundColor: 'transparent' }}>
                        <Loader indeterminate size='massive' style={{ color: 'black' }}>Carregando...</Loader>
                    </Dimmer>
                </Segment>
            }
            {!onLoad &&
                <>
                    <Segment className='primary-color' style={{ margin: 'auto', width: '90%' }}>
                        <h2>{pet.name}</h2>
                    </Segment>
                    <Container>
                        <Container>
                            {renderProfile(pet)}
                        </Container>
                        <Divider />
                        <ResponsiveTab panes={panes} />
                    </Container>
                </>
            }
            <br />
        </Container>
    );
};

export default PetProfilePage;
