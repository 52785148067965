import React from 'react';
import { Card, Icon, Segment } from 'semantic-ui-react';

const ChipInfo = ({ chipInfo }) => {
  if (!chipInfo) {
    return <Segment>Informação do chip não disponível.</Segment>;
  }

  return (
    <Card style={{margin: 'auto'}}>
      <Card.Content>
        <Card.Header>
          <Icon name='microchip' /> Chip {chipInfo.chipNumber}
        </Card.Header>
        <Card.Meta>Data: {chipInfo.date || 'Não especificada'}</Card.Meta>
        <Card.Description>Local de aplicação: {chipInfo.place || 'Não especificado'}</Card.Description>
      </Card.Content>
    </Card>
  );
};

export default ChipInfo;
