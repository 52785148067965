const breeds = `Pitão ametista
Tartaruga Angonoka
Tegu preto e branco argentino
Pitão bola
Lagarto de língua azul
Jibóia
Tartaruga do pântano
Tartaruga Bolson
Pitão birmanesa
Tartaruga estrela birmanesa
Pitão-rocha da África Central
Dragão barbudo central
Tartaruga de caixa comum
Lagartixa com crista
Camaleão de pescoço abalado
Lagartixa diurna de cauda chata
tartaruga grega
Anolis carolinensis
Iguana verde
Iguana verde em cativeiro
A tartaruga de Hermann
Tartaruga estrela indiana
Lagarto de parede italiano
O camaleão de Jackson
Lagartixa leopardo
Lygodactylus williamsi
MacGyver, o Lagarto
Lagartixa do dia da floresta da planície de Maurício
Lagarto jacaré mexicano
Monitor do Nilo
Captura de tartarugas pintadas
Phelsuma pronki
Pogoná
Dragão de Rankin
Controle deslizante de orelha vermelha
Pitão reticulado
Tartaruga russa
Monitor de savana
Sceloporus malachiticus
Scincus scincus
Tartaruga aranha
Lagartixa
Tiliqua scincoides
Uromastyx
Vipera kaznakovi`;

export var reptileBreedList = breeds.split('\n').map(function(line) {
    return line.replace(/\[\d+\]/g, '').trim();
});