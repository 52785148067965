import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext(); // Criação do contexto

export const useAuth = () => useContext(AuthContext); // Hook para acessar o contexto

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // Função para simular login
  const login = (userData) => {
    setUser(userData);
  };

  // Função para simular logout
  const logout = () => {
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
