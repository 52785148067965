import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Dropdown, Icon, Image, Menu, Modal, ModalActions } from 'semantic-ui-react';
import RegisterForm from '../account/Register';
import LoginForm from '../account/Login';
import { useAuth } from '../account/AuthContext';
import { getAuth, deleteUser } from "firebase/auth";
import { LogErrors, notifyError, notifySuccess } from '../../resources/lib';
import { addDataToCollection } from '../../resources/firebaseLib';
import { queuesCollection } from '../../resources/const/firebaseCollections';
import ConfimationModal from '../modals/ConfimationModal';

const Navbar = () => {
    const { user, logout } = useAuth();
    const [registerModalOpen, setRegisterModalOpen] = useState(false);
    const [loginModalOpen, setLoginModalOpen] = useState(false);

    const handleOpenLoginModal = () => setLoginModalOpen(true);
    const handleCloseLoginModal = () => setLoginModalOpen(false);
    const handleOpenRegisterModal = () => setRegisterModalOpen(true);
    const handleCloseRegisterModal = () => setRegisterModalOpen(false);

    function logoutSession() {
        handleCloseLoginModal();
        handleCloseRegisterModal();
        logout();
    }

    const deleteAccount = async (value) => {
        if (value) {
            const auth = getAuth();
            const firebaseUser = auth.currentUser;

            const result = await populateCollection(user);
            if (result) {
                deleteUser(firebaseUser).then(() => {
                    notifySuccess("Conta apagada com sucesso.");
                    logoutSession();
                }).catch((error) => {
                    LogErrors(error);
                    notifyError("Erro ao apagar a conta.");
                });
            }
            else {
                notifyError("Erro ao adicionar a conta à lista de contas a apagar.");
            }
        }
    };

    async function populateCollection(data) {
        try {
            const addData = {
                docId: data.uid,
                collection: 'userAccounts'
            };

            return await addDataToCollection(queuesCollection, addData);
        } catch (error) {
            LogErrors(error);
            return false;
        }
    };

    return (
        <Menu stackable inverted style={{ margin: '0px' }} size='huge' className='primary-color square'>
            <Menu.Item as={Link} to="/" name='início' />
            <Menu.Item as={Link} to="/about" name='sobre' />
            <Menu.Item as={Link} to="/basic-care" name='cuidados básicos' />
            <Menu.Menu position='right'>
                {user ? (
                    <Menu.Item style={{ padding: '0px'}}>
                        {user ? user.photo === 'icon' ? (
                            <Icon name='user' style={{ marginLeft: '10px' }} />
                        ) : (
                            <Image avatar src={user.photo} style={{ marginLeft: '10px' }} />
                        ) : (
                            <></>
                        )}
                        <Dropdown item text={user.name} style={{ maxWidth: '90%', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}} >
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={logoutSession}>Sair</Dropdown.Item>
                                <ConfimationModal
                                    title='Tem certeza de que deseja apagar a sua conta?'
                                    trigger={
                                        <Dropdown.Item style={{ color: 'black', cursor: 'pointer' }}>
                                            Apagar Conta
                                        </Dropdown.Item>
                                    }
                                    action={deleteAccount}
                                />
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Item>
                ) : (
                    <>
                        <Menu.Item name='entrar' onClick={handleOpenLoginModal} />
                        <Modal
                            open={loginModalOpen}
                            onClose={handleCloseLoginModal}
                            size='tiny'
                        >
                            <Modal.Header className='primary-color'>Entrar</Modal.Header>
                            <Modal.Content>
                                <LoginForm />
                            </Modal.Content>
                            <ModalActions className='tertiary-color'>
                                <Button negative onClick={() => setLoginModalOpen(false)}>
                                    Fechar
                                </Button>
                            </ModalActions>
                        </Modal>
                        <Menu.Item name='registrar' onClick={handleOpenRegisterModal} />
                        <Modal
                            open={registerModalOpen}
                            onClose={handleCloseRegisterModal}
                            size='tiny'
                        >
                            <Modal.Header className='primary-color'>Registre-se</Modal.Header>
                            <Modal.Content>
                                <RegisterForm />
                            </Modal.Content>
                            <ModalActions className='tertiary-color'>
                                <Button negative onClick={() => setRegisterModalOpen(false)}>
                                    Fechar
                                </Button>
                            </ModalActions>
                        </Modal>
                    </>
                )}
            </Menu.Menu>
        </Menu>
    );
};

export default Navbar;
