import React, { useState } from 'react';
import { Button, Form, Input, Container, Select, Dropdown } from 'semantic-ui-react';
import { LogErrors, getCurrentDateTime, getReptileBreeds, notifyError, notifySuccess, removeId } from '../../resources/lib';
import { addDataToCollection, updateDataByDocId } from '../../resources/firebaseLib';
import { reptilesCollection } from '../../resources/const/firebaseCollections';
import { useAuth } from '../account/AuthContext';
import { reptileSkinPatterns, sexOptions } from '../../resources/const/dropdownOptions';

const ReptileForm = ({ pet, reload, closeModal }) => {
  const { user } = useAuth();
  const [petProfile, setPetProfile] = useState(pet ? pet : {
    type: 'reptile',
    name: '',
    birthDate: '',
    species: '',
    sex: '',
    skinColor: '',
    skinPattern: ''
  });
  const isUpdate = pet ? true : false;

  const breedOptions = getReptileBreeds();

  const handleChange = (e, { name, value }) => {
    setPetProfile({ ...petProfile, [name]: value });
  };

  const handleDropChange = (e, { name, value }) => setPetProfile({ ...petProfile, [name]: value });

  async function populateCollection(data, uid) {
    try {
      const addData = {
        uid: uid,
        type: data.type,
        name: data.name.trim(),
        birthDate: data.birthDate,
        species: data.species,
        sex: data.sex,
        skinColor: data.skinColor,
        skinPattern: data.skinPattern,
        creationDate: getCurrentDateTime(),
        isDelete: 0
      };

      return await addDataToCollection(reptilesCollection, addData);
    } catch (error) {
      LogErrors(error);
      return false;
    }
  };

  const validObject = () => {
    return (petProfile.name.trim() !== '' &&
      petProfile.birthDate !== '' &&
      petProfile.breed !== '' &&
      petProfile.sex !== '');
  }

  const handleSubmit = async () => {
    if (!isUpdate) {
      const result = await populateCollection(petProfile, user.uid);
      if (result) {
        notifySuccess('Pet adicionado com sucesso.');
        reload(user);
        closeModal();
      }
      else {
        notifyError('Falha ao criar o seu pet.');
        closeModal();
      }
    }
    else {
      const result = await updateDataByDocId(reptilesCollection, petProfile.id, removeId(petProfile));
      if (result) {
        notifySuccess('Pet atualizado com sucesso.');
        reload(user);
        closeModal();
      }
      else {
        notifyError('Falha ao atualizar o seu pet.');
        closeModal();
      }
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Form.Field>
          <label>Nome</label>
          <Input
            placeholder='Nome do Pet'
            name='name'
            value={petProfile.name}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Data de Nascimento</label>
          <Input
            placeholder='Data de Nascimento'
            name='birthDate'
            type='date'
            value={petProfile.birthDate}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Espécie</label>
          <Dropdown
            placeholder='Espécie do Réptil'
            name='species'
            fluid
            search
            selection
            options={breedOptions}
            value={petProfile.species}
            onChange={handleDropChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Sexo</label>
          <Select
            placeholder='Selecione o sexo'
            name='sex'
            options={sexOptions}
            value={petProfile.sex}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Pele</label>
          <Input
            placeholder='Côr'
            name='skinColor'
            value={petProfile.skinColor}
            onChange={handleChange}
            style={{ paddingBottom: '10px' }}
          />
          <Dropdown
            placeholder='Padrão'
            name='skinPattern'
            fluid
            search
            selection
            options={reptileSkinPatterns}
            value={petProfile.skinPattern}
            onChange={handleDropChange}
          />
        </Form.Field>
        <Button type='submit' className='primary-color' disabled={!validObject()}>Gravar Perfil do Pet</Button>
      </Form>
    </Container>
  );
};

export default ReptileForm;
