import React from 'react';
import { Grid, Card, Icon } from 'semantic-ui-react';
import { motion } from 'framer-motion';

const FeaturesSection = () => {
  return (
    <Grid textAlign="center" stackable style={{ margin: 'auto' }}>
      <Grid.Row columns={3}>
        {[
          { name: 'paw', header: 'Perfil Completo do Pet', description: 'Mantenha todas as informações importantes do seu pet em um só lugar.' },
          { name: 'heartbeat', header: 'Saúde e Bem-Estar', description: 'Acompanhe a saúde do seu pet com dicas e lembretes personalizados.' },
          { name: 'find', header: 'Encontre Pets Perdidos', description: 'Contribua para a rede de pets encontrados e ajude a reunir famílias.' },
        ].map((card, index) => (
          <Grid.Column key={index} style={{ display: 'flex', justifyContent: 'center' }}>
            <motion.div
              initial={{ x: index % 2 === 0 ? -100 : 100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <Card className="primary-color" style={{ boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', transition: '0.3s' }} as={motion.div} whileHover={{ scale: 1.05 }}>
                <Card.Content>
                  <div style={{ textAlign: 'center' }}>
                    <Icon name={card.name} size='big' />
                  </div>
                  <Card.Header textAlign="center">{card.header}</Card.Header>
                  <Card.Description textAlign="center">{card.description}</Card.Description>
                </Card.Content>
              </Card>
            </motion.div>
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>

  );
};

export default FeaturesSection;

