import React, { useEffect, useState } from 'react';
import { Button, Header, Icon, List, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import CreationModals from './modals/CreationModals';
import WeightForm from './forms/WeightForm';
import { measurementsCollection } from '../../resources/const/firebaseCollections';
import { deleteDocById } from '../../resources/firebaseLib';
import { notifyError, notifySuccess } from '../../resources/lib';
import ConfimationModal from '../modals/ConfimationModal';

const MeasurementsList = ({ pet, measurements, isView, reload }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleModal = async (value, item) => {
        if (value) {
            const result = deleteDocById(measurementsCollection, item.id);
            if (result) {
                notifySuccess(`Pesagem apagada com sucesso.`);
            }
            else {
                notifyError(`Falha ao apagar Pesagem.`);
            }
            reload(pet);
        }
    };

    return (
        <>
            <Header as="h5">Lista de Pesagens</Header>
            {measurements.length === 0 ? (
                <Segment>Nenhuma pesagem adicionada.</Segment>
            ) : (
                <List divided relaxed style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    {measurements.map((measurement, index) => (
                        <List.Item key={index}>
                            {windowWidth > 758 &&
                                <List.Icon name='balance scale' size='large' verticalAlign='middle' />
                            }
                            <List.Content>
                                {!isView ? (
                                    <CreationModals
                                        title='Adicionar Pesagem'
                                        trigger={<List.Header as={Link}>{measurement.weight.replaceAll('.', ',')} kg</List.Header>}
                                    >
                                        <WeightForm pet={pet} measurement={measurement} reload={reload} />
                                    </CreationModals>
                                ) : (
                                    <List.Header as={Link}>{measurement.weight.replaceAll('.', ',')} kg</List.Header>
                                )
                                }
                                {!isView && windowWidth > 758 &&
                                    <List.Content floated='right'>
                                        <ConfimationModal
                                            title='Tem certeza de que deseja apagar esta pesagem?'
                                            trigger={<Button inverted color='red'><Icon name='trash' />Apagar</Button>}
                                            action={handleModal}
                                            item={measurement} />
                                    </List.Content>
                                }
                                <List.Description>{measurement.date}</List.Description>
                                <List.Description>{measurement.place}</List.Description>
                                {!isView && windowWidth <= 758 &&
                                    <List.Description>
                                        <ConfimationModal
                                            title='Tem certeza de que deseja apagar esta pesagem?'
                                            trigger={<Button inverted color='red'><Icon name='trash' />Apagar</Button>}
                                            action={handleModal}
                                            item={measurement} />
                                    </List.Description>
                                }
                            </List.Content>
                        </List.Item>
                    ))}
                </List >
            )}
            {!isView &&
                <CreationModals
                    title='Adicionar Pesagem'
                    trigger={<Button
                        icon='add'
                        content='Adicionar Pesagem'
                        className='primary-color'
                    />}
                >
                    <WeightForm pet={pet} reload={reload} />
                </CreationModals>
            }
        </>
    );
};

export default MeasurementsList;