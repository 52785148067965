import React, { useState } from 'react';
import { Tab, Dropdown, Segment } from 'semantic-ui-react';

const ResponsiveTab = ({ panes }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const isMobile = window.innerWidth < 758;

    const handleTabChange = (e, { activeIndex }) => setActiveIndex(activeIndex);

    const paneOptions = panes.map((pane, idx) => ({
        key: idx,
        text: pane.menuItem,
        value: idx,
    }));

    return (
        <>
            {isMobile ? (
                <Dropdown
                    selection
                    options={paneOptions}
                    value={activeIndex}
                    onChange={(e, data) => setActiveIndex(data.value)}
                    style={{marginLeft: '15px'}}
                />
            ) : (
                <Tab panes={panes} activeIndex={activeIndex} onTabChange={handleTabChange} />
            )}
            {isMobile &&
                <Segment>{panes[activeIndex].render()}</Segment>
            }
        </>
    );
};

export default ResponsiveTab;