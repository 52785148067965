import React from 'react';
import { Accordion, Segment } from 'semantic-ui-react';

const ReptileCareDetails = () => {
    const panels = [
        {
            key: 'veterinary-exam',
            title: 'Exame Veterinário Inicial',
            content: {
                content: (
                    <div>
                        <p>Ao adquirir um novo réptil: É recomendável que você leve seu réptil para uma consulta inicial com um veterinário especializado em répteis para um check-up de saúde e orientações específicas sobre cuidados com a espécie.</p>
                    </div>
                ),
            },
        },
        {
            key: 'regular-checkups',
            title: 'Check-ups Regulares',
            content: {
                content: (
                    <div>
                        <p>Anualmente: Consultas veterinárias regulares são importantes para monitorar a saúde do réptil e prevenir problemas.</p>
                    </div>
                ),
            },
        },
        {
            key: 'adequate-habitat',
            title: 'Habitat Adequado',
            content: {
                content: (
                    <div>
                        <p>Temperatura e umidade: Mantenha um ambiente controlado com temperaturas e umidades específicas para a espécie, o que muitas vezes inclui a instalação de lâmpadas de aquecimento e dispositivos de umidificação.</p>
                        <p>Substrato adequado: Use um substrato apropriado para a espécie que não cause impactação se ingerido e que facilite a manutenção da higiene.</p>
                    </div>
                ),
            },
        },
        {
            key: 'nutrition',
            title: 'Nutrição Adequada',
            content: {
                content: (
                    <div>
                        <p>Dieta específica para a espécie: Alimente seu réptil com uma dieta apropriada que pode incluir insetos, vegetais, frutas ou preparados comerciais, dependendo da espécie.</p>
                        <p>Suplementos: Muitos répteis necessitam de suplementos de cálcio e vitaminas, especialmente vitamina D3, para prevenir deficiências nutricionais.</p>
                    </div>
                ),
            },
        },
        {
            key: 'environment-maintenance',
            title: 'Manutenção do Ambiente',
            content: {
                content: (
                    <div>
                        <p>Limpeza regular: Limpe o habitat regularmente para prevenir o acúmulo de bactérias e fungos, removendo fezes e restos de alimentos diariamente.</p>
                    </div>
                ),
            },
        },
        {
            key: 'parasite-prevention',
            title: 'Prevenção de Parasitas',
            content: {
                content: (
                    <div>
                        <p>Exames periódicos: Faça exames periódicos para parasitas internos e externos, especialmente se o seu réptil teve contato com outros animais ou ambientes externos.</p>
                    </div>
                ),
            },
        }
    ];

    return (
        <Segment basic>
            <Accordion panels={panels} styled fluid />
        </Segment>
    );
};

export default ReptileCareDetails;
