import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css'
import Navbar from './components/navbar/Navbar'
import Footer from './components/footer/Footer'
import Home from './components/body/home/Home'
import { ToastContainer } from 'react-toastify'
import { AuthProvider } from './components/account/AuthContext'
import AboutUs from './components/body/about/AboutUs'
import FAQPage from './components/body/faq/FAQPage'
import SupportPage from './components/body/support/Support'
import FloatingChatIcon from './components/global/FloatingChatIcon'
import PetProfilePage from './components/preview/PreviewPage'
import 'react-image-crop/dist/ReactCrop.css';
import BasicCarePage from './components/body/basicCare/components/BasicCarePage'

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Navbar />
        <div className='scrollable-container'>
          <ToastContainer />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/basic-care" element={<BasicCarePage />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/support" element={<SupportPage />} />
            <Route path="/pet/share/:type/:id" element={<PetProfilePage />} />
          </Routes>
          <FloatingChatIcon />
          <Footer />
        </div>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App
