import React, { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';

const VeterinarianInfoModal = ({ veterinarian, trigger: Trigger }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Modal
            trigger={<div onClick={handleOpen}>{Trigger}</div>}
            open={open}
            onClose={handleClose}
            size='small'
        >
            <Modal.Header className='primary-color'>Informações do Veterinário</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <p><strong>Nome:</strong> {veterinarian.name}</p>
                    <p><strong>Clínica:</strong> {veterinarian.clinicName}</p>
                    <p><strong>Cidade:</strong> {veterinarian.city}</p>
                    <p><strong>Contato:</strong> {veterinarian.contact ? veterinarian.contact : 'Contato não fornecido.'}</p>
                    <p><strong>Email:</strong> {veterinarian.email ? veterinarian.email : 'Email não fornecido.'}</p>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions className='tertiary-color'>
                <Button negative onClick={handleClose}>Fechar</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default VeterinarianInfoModal;
