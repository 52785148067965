import React from 'react';
import { Accordion, Segment } from 'semantic-ui-react';

const CatCareDetails = () => {
    const panels = [
        {
            key: 'veterinary-exam',
            title: 'Exame Veterinário Inicial',
            content: {
                content: (
                    <div>
                        <p>Ao adquirir um novo gato: Leve ao veterinário para um check-up inicial. Isso pode incluir exames físicos, testes para doenças infecciosas e conselhos sobre cuidados adequados.</p>
                    </div>
                ),
            },
        },
        {
            key: 'vaccinations-kittens',
            title: 'Vacinações Iniciais (Filhotes)',
            content: {
                content: (
                    <div>
                        <p>6 a 8 semanas: Primeira dose da vacina tríplice felina (calicivírus, herpesvírus e panleucopenia).</p>
                        <p>10 a 12 semanas: Segunda dose da tríplice felina.</p>
                        <p>14 a 16 semanas: Terceira dose da tríplice felina e primeira dose da vacina antirrábica.</p>
                    </div>
                ),
            },
        },
        {
            key: 'vaccinations-adults',
            title: 'Vacinações Anuais (Adultos)',
            content: {
                content: (
                    <div>
                        <p>Anualmente: Reforço da tríplice felina.</p>
                        <p>Anualmente: Reforço da vacina antirrábica (conforme legislação local).</p>
                    </div>
                ),
            },
        },
        {
            key: 'parasite-prevention',
            title: 'Prevenção de Parasitas',
            content: {
                content: (
                    <div>
                        <p>Mensal: Tratamento preventivo contra pulgas e carrapatos.</p>
                        <p>Mensal ou Trimestral: Tratamento preventivo contra vermes intestinais e, se necessário, contra vermes do coração.</p>
                    </div>
                ),
            },
        },
        {
            key: 'chip-and-sterilization',
            title: 'Colocação de Chip e Esterilização',
            content: {
                content: (
                    <div>
                        <p>Colocação de Chip: Durante a primeira consulta veterinária ou ao ser vacinado.</p>
                        <p>Esterilização (Castração ou Ovariohisterectomia): 5 a 6 meses, recomendado para prevenir gestações indesejadas e reduzir comportamentos associados a hormônios.</p>
                    </div>
                ),
            },
        }
    ];

    return (
        <Segment basic>
            <Accordion panels={panels} styled fluid />
        </Segment>
    );
};

export default CatCareDetails;
