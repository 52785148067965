import React from 'react';
import { motion } from 'framer-motion';

const Grow = ({ children }) => {
    return (
        <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            animate={{ scale: [1, 1.05, 1], opacity: [0.8, 1, 0.8] }}
            transition={{ duration: 1.5, repeat: Infinity }}
        >
            {children}
        </motion.div>
    );
};

export default Grow;
