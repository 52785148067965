import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import SupportChatModal from './SupportChatModal';
import { useAuth } from '../account/AuthContext';

const FloatingChatIcon = () => {
    const { user } = useAuth();
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    if (!user) return null;

    return (
        <div style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            cursor: 'pointer',
            zIndex: 1000
        }} onClick={openModal}>
            <Icon name="comments" size="big" circular inverted color="teal" />
            <SupportChatModal isOpen={isOpen} closeModal={closeModal} />
        </div>
    );
};

export default FloatingChatIcon;
