import React from 'react';
import { Accordion, Container, Grid, Image, Segment } from 'semantic-ui-react';
import DogCareDetails from './species/DogCareDetails';
import CatCareDetails from './species/CatCareDetails';
import BirdCareDetails from './species/BirdCareDetails';
import RodentCareDetails from './species/RodentCareDetails';
import ReptileCareDetails from './species/ReptileCareDetails';
import dogPhoto from '../../../../resources/avatars/dog.png'
import catPhoto from '../../../../resources/avatars/cat.png'
import birdPhoto from '../../../../resources/avatars/bird.png'
import rodentPhoto from '../../../../resources/avatars/rodent.png'
import reptilePhoto from '../../../../resources/avatars/reptile.png'
import BasicCareHeader from './BasicCareHeader';
import FadeInOnScroll from '../../../global/FadeInOnScroll';
import AppearOnScroll from '../../../global/AppearOnScroll';
import SeasonalCareAlert from './SeasonalCareAlert';


const BasicCarePage = () => {
    const isMobile = window.innerWidth < 758;

    const panels = [
        {
            key: 'dogs',
            title: 'Cães',
            content: {
                content: (
                    <AppearOnScroll>
                        <Grid columns={isMobile ? 1 : 2}>
                            <Grid.Column width={isMobile ? 16 : 4}>
                                <Image centered className='image-div' size={isMobile ? 'small' : 'medium'} src={dogPhoto} />
                            </Grid.Column>
                            <Grid.Column width={isMobile ? 16 : 12}>
                                <DogCareDetails />
                            </Grid.Column>
                        </Grid>
                    </AppearOnScroll>
                ),
            },
        },
        {
            key: 'cats',
            title: 'Gatos',
            content: {
                content: (
                    <AppearOnScroll>
                        <Grid columns={isMobile ? 1 : 2}>
                            <Grid.Column width={isMobile ? 16 : 12}>
                                <CatCareDetails />
                            </Grid.Column>
                            <Grid.Column width={isMobile ? 16 : 4}>
                                <Image centered className='image-div' size={isMobile ? 'small' : 'medium'} src={catPhoto} />
                            </Grid.Column>
                        </Grid>
                    </AppearOnScroll>
                ),
            },
        },
        {
            key: 'birds',
            title: 'Pássaros',
            content: {
                content: (
                    <AppearOnScroll>
                        <Grid columns={isMobile ? 1 : 2}>
                            <Grid.Column width={isMobile ? 16 : 4}>
                                <Image centered className='image-div' size={isMobile ? 'small' : 'medium'} src={birdPhoto} />
                            </Grid.Column>
                            <Grid.Column width={isMobile ? 16 : 12}>
                                <BirdCareDetails />
                            </Grid.Column>
                        </Grid>
                    </AppearOnScroll>
                ),
            },
        },
        {
            key: 'rodents',
            title: 'Reodores',
            content: {
                content: (
                    <AppearOnScroll>
                        <Grid columns={isMobile ? 1 : 2}>
                            <Grid.Column width={isMobile ? 16 : 12}>
                                <RodentCareDetails />
                            </Grid.Column>
                            <Grid.Column width={isMobile ? 16 : 4}>
                                <Image centered className='image-div' size={isMobile ? 'small' : 'medium'} src={rodentPhoto} />
                            </Grid.Column>
                        </Grid>
                    </AppearOnScroll>
                ),
            },
        },
        {
            key: 'reptiles',
            title: 'Reptéis',
            content: {
                content: (
                    <AppearOnScroll>
                        <Grid columns={isMobile ? 1 : 2}>
                            <Grid.Column width={isMobile ? 16 : 4}>
                                <Image centered className='image-div' size={isMobile ? 'small' : 'medium'} src={reptilePhoto} />
                            </Grid.Column>
                            <Grid.Column width={isMobile ? 16 : 12}>
                                <ReptileCareDetails />
                            </Grid.Column>
                        </Grid>
                    </AppearOnScroll>
                ),
            },
        }
    ];

    return (
        <Container fluid>
            <Segment inverted style={{ padding: '2em 0em' }} className='square'>
                <FadeInOnScroll><BasicCareHeader /></FadeInOnScroll>
                <FadeInOnScroll>
                    <Accordion panels={panels} styled fluid style={{ maxWidth: '90%', margin: 'auto' }} className='primary-color' />
                </FadeInOnScroll>
            </Segment>
            <AppearOnScroll>
                <SeasonalCareAlert />
            </AppearOnScroll>
        </Container>
    );
};

export default BasicCarePage;

