const breeds = `Affenpinscher[8]
Galgo Afegão[9]
Africanos[10]
Aidi[11]
Airedale Terrier[12]
Akbash[13]
Akita[14]
Aksaray Malaklisi
Alano Espanhol[15]
Bulldog de Sangue Azul Alapaha [16]
Husky do Alasca[17]
Klee Kai do Alasca[18]
Malamute do Alasca[19]
Alopekis
Dachsbracke alpino
Bulldog Americano
Intimidador Americano
Cocker Spaniel Americano[22]
Coonhound inglês americano
Cão esquimó americano[24]
Foxhound americano
Terrier calvo americano
Cão Leopardo Americano
Pitbull Terrier Americano[27]
Staffordshire Terrier Americano[27]
Spaniel de Água Americano[28]
Anglo-Français de Petite Vénerie[29]
Appenzeller Sennenhund[30]
Ponteiro Ariège
Ariégeois[31]
Pila argentina
Armando[32]
Gampr armênio
Cão Artois[33]
Cão de gado australiano
Kelpie Australiano[34]
Pastor Australiano[35]
Terrier sedoso australiano
Cão de gado australiano com cauda atarracada [37]
Terrier Australiano[36]
Sabujo Preto e Castanho Austríaco[38]
Pinscher austríaco[39]
Azawakh[40]
Bắc Hà
Bakharwal
Cão Banjara
Cachorro Bankhar
Cão de caça Barak
Barbado da Terceira[42]
Barbeto[43]
Basenji[44]
Cão Pastor Basco[45]
Basset Artésien Normand[46]
Basset Bleu de Gascogne[47]
Basset Fauve da Bretanha[48]
Basset Hound[49]
Cão de montanha da Baviera[50]
Beagle[49]
Beagle Harrier[51]
Collie barbudo[52]
Beauceron[53]
Bedlington Terrier[54]
Pastor Belga[55]
Pastor Bergamasco[56]
Berger Picard[57]
Cão de montanha Bernese[30]
Bichon Frisé[58]
Biewer Terrier
Billy[59]
Coonhound Preto e Castanho[60]
Terrier Preto e Castanho[61]
Elkhound Norueguês Negro[62]
Terrier Russo Negro[63]
Boca Negra Cur[64]
Cão de caça[65]
Rendado Azul[66]
Spaniel Azul da Picardia[67]
Coonhound Bluetick[60]
Boerboel[68]
Pastor Boêmio[69]
Bolonhesa[70]
Border Collie[71]
Border Terrier[72]
Borzói[73]
Boston Terrier[74]
Bouvier des Ardenas[75]
Bouvier des Flandres[76]
Pugilista[77]
Spaniel Boykin[78]
Braco Italiano[79]
Braque d'Auvergne[80]
Braque du Bourbonnais[81]
Braque Français[82]
Braque Saint-Germain[81]
Terrier Brasileiro[83]
Briard[53]
Briquet de Provença
Briquete Griffon Vendéen[84]
Bretanha[67]
Broholmer[85]
Bruno Jura Cão[86]
Cão Pastor Bucovina[87]
Cão Búlgaro
Scenthound Búlgaro
Touro Árabe
Bull Terrier[88]
Buldogue[89]
Bullmastiff[89]
Intimidador Kutta
Ponteiro de Burgos[90]
Ca Me Mallorquí[91]
Cá de Bou[92]
Ca Rater Mallorquí
Terrier de Cairn[93]
Calupoh
Buldogue Campeiro
Can de Chira
Can de Palleiro
Cão Canaã[94]
Cão esquimó canadense[19]
Cane Corso[11]
Cana de Oropa
Cane Paratore
Cão de Água Cantábrico
Cão de Gado Transmontano[95]
Cardigã Welsh Corgi[96]
Carea Leonés
Cachorro Carolina[97]
Cão Pastor dos Cárpatos[98]
Cão Castro Laboreiro[99]
Cão Leopardo Catahoula[100]
Cão pastor catalão[101]
Cão pastor caucasiano[102]
Cavalier King Charles Spaniel[103]
Cão pastor da Ásia Central[104]
Český fousek[105]
Český strakatý pes
Cesky Terrier[106]
Chesapeake Bay Retriever[107]
Chien Français Blanc et Noir[108]
Chien Français Blanc et Orange[109]
Chien Français Tricolore[108]
Chihuahua[110]
Terrier Chileno
Cão de crista chinês[111]
Chinook[112]
Chippiparai[113]
Chongqing
Chortai[114]
Chow Chow[115]
Cachorro de trenó Chukotka
Cimarrón Uruguaio
Cirneco dell'Etna[116]
Clumber Spaniel[117]
Cão Fino Colombiano
Buldogue continental
Cão da Córsega[69]
Algodão de Tulear[118]
Cão cretense[119]
Cão pastor croata[98]
Retriever de pêlo encaracolado[107]
Cão-lobo checoslovaco[120]
D-K
Dachshund[121]
Dálmata[122]
Dandie Dinmont Terrier[123]
Spitz dinamarquês
Farmdog Dinamarquês-Sueco[124]
Dinamarca Feist[125]
Dikkulak[126]
Dingo [nota 1]
Doberman[50]
Dogo Argentino[128]
Dogo Sardesco
Dogue Brasileiro[128]
Dogue de Bordéus[129]
Donggyeongi
Drentse Patrijshond[130]
Drever[131]
Mais Dunker[132]
Pastor Holandês[133]
Smoushond holandês
Laika da Sibéria Oriental[135]
Pastor do Leste Europeu[136]
Cão Pelado Equatoriano[137]
Cocker Spaniel Inglês[138]
Foxhound Inglês
Mastim Inglês
Setter Inglês[140]
Pastor Inglês[141]
Springer Spaniel Inglês[142]
Toy Terrier Inglês (Preto e Castanho)[143]
Cão da montanha Entlebucher[144]
Erbi Txakur
Cão de caça da Estônia[145]
Cão da Serra da Estrela[99]
Eurásio[115]
Cão pastor faroês
Spaniel de Campo[146]
Fila Brasileiro[128]
Cão Finlandês[147]
Lapphund finlandês[148]
Spitz Finlandês[149]
Retriever de revestimento plano[150]
Buldogue Francês[151]
Spaniel Francês[152]
Galgo Espanhol[153]
Gascão Saintongeois[29]
Cão pastor gaúcho
Pastor Georgiano
Cão Alemão
Ponteiro Alemão de Pêlo Comprido[154]
Pinscher Alemão[8]
Ponteiro Alemão de Pêlo Áspero[155]
Pastor Alemão[156]
Ponteiro Alemão de Pêlo Curto[154]
Spaniel Alemão[117]
Spitz Alemão[157]
Ponteiro Alemão de Pêlo Arame[154]
Schnauzer gigante[158]
Vale do Imaal Terrier
Golden Retriever[160]
Gończy Polski[161]
Gordon Setter[140]
Grand Anglo-Français Blanc et Noir[162]
Grand Anglo-Français Blanc et Orange[162]
Grande Anglo-Français Tricolore[162]
Grande Basset Griffon Vendéen[163]
Grande Azul da Gasconha[164]
Grande Griffon Vendéen[84]
Dogue Alemão[165]
Grande Cão de Montanha Suíço[144]
Harehound Grego[166]
Pastor Grego[167]
Cão da Groenlândia[168]
Galgo
Grifo Azul de Gasconha[47]
Grifo de Bruxelas[169]
Grifo Fauve da Bretanha[48]
Grifo Nivernais[46]
Dogo guatemalteco
Gaivota Dong
Gaivota Terrier
Elkhound de Hällefors
Cão Halden
Hamiltonstövare[170]
Cão de Hanôver[171]
Harrier[172]
Havanês[70]
Cão pastor do Himalaia[173]
Cachorro bobtail Hmong
Hokkaido[14]
Hovawart[174]
Caçada[175]
Cão de Higen[147]
Cão de Ibiza[116]
Cão pastor islandês[176]
Cachorro pária indiano
Spitz indiano
Setter Irlandês Vermelho e Branco[177]
Setter Irlandês[177]
Terrier Irlandês
Spaniel de Água Irlandês[178]
Wolfhound Irlandês[179]
Cão de pêlo grosso da Ístria [180]
Cão de Pêlo Curto da Ístria[181]
Galgo Italiano[182]
Jack Russel Terrier[183]
Jagd Terrier[106]
Jamthund[184]
Queixo Japonês[185]
Spitz Japonês[186]
Terrier Japonês[187]
Jeju
Jonangi
Kai Ken
Kaikadi[188]
Cão de trenó Kamchatka
Cão Pastor Kangal
Kanni[41]
Karakachan
Cão Urso da Carélia[189]
Karelo-Laika finlandesa
Cars[190]
Pastor Cársico[191]
Keehond[192]
Beagle Kerry[51]
Terrier Azul Kerry[193]
Khala[194]
Spaniel Rei Carlos[185]
Rei Pastor[75]
Kintamani
Kishu[195]
Kokoni
Kombai[196]
Komondor[197]
Kooikerhondje[198]
Koolie
Jindo Coreano[199]
Kromfohrländer[187]
Kuchi[200]
Kunming
Mastim Curdo
Kuvasz[201]
E-D
Labrador Retriever[202]
Lagotto Romagnolo[203]
Lai
Lakeland Terrier[72]
Lancashire Heeler[35]
Landseer[204]
Pastor da Lapônia[149]
Grande Münsterländer[205]
Leonberger[128]
Levriero Sardo
Lhasa Apso
Cachorro Liangshan[206]
Cão de caça lituano[145]
Lobito Herreño
Löwchen[118]
Lucas Terrier
Lupo Italiano
Husky do Rio Mackenzie
Magiar Agár[207]
Cão de caça Mahratta[208]
Cão pastor de Maiorca
Maltês[58]
Manchester Terrier[143]
Maneto[209]
Markiesje
Cão pastor Maremmano-Abruzzês[56]
McNab
Pastor americano em miniatura
Bull Terrier Miniatura[88]
Fox Terrier Miniatura
Pinscher miniatura[39]
Schnauzer Miniatura[210]
Molosso do Épiro
vira-lata
Cão de montanha montenegrino[211]
Cão de guarda de Moscou
Montanha Cur[166]
Feitiço da Montanha[125]
Cão de Mudhol[212]
Mudi[213]
Mastim Napolitano[214]
Nenets pastoreando Laika
Cachorro cantando da Nova Guiné
Cão-cabeceiro da Nova Zelândia
Terra Nova[215]
Terrier Norfolk[93]
Norrbottenspets[216]
Cão Inuit do Norte
Buhund Norueguês[176]
Elkhound Norueguês[217]
Lundehund norueguês[218]
Norwich Terrier[219]
Retriever de pedágio de pato da Nova Escócia [198]
Ponteiro Dinamarquês Antigo[220]
Cão pastor inglês antigo[60]
Antigo Bulldogge Inglês
Cão-lontra[139]
Pachón Navarro
Deerhound dos pampas
Papillon[221]
Pároco Russell Terrier[222]
Pastor Garafiano[223]
Pastore della Lessinia e del Lagorai
Cão pastor patagônico
Patterdale Terrier[224]
Pequinês[225]
Corgi Galês Pembroke[96]
Perdigueiro Galego
Perro Majorero
Cão pelado peruano[226]
Petit Basset Griffon Vendéen[163]
Petit Bleu de Gascogne[164]
Falène[221]
Cão do Faraó[227]
Phu Quoc Ridgeback
Spaniel da Picardia[152]
Plummer Terrier[224]
Cão de caça[228]
Podenco Andaluz
Podenco Canário[94]
Podenco Valenciano
Ponteiro[150]
Poitevin[180]
Galgo Polonês[229]
Cão Polonês[230]
Cão pastor polonês das planícies[231]
Pomerânia[232]
Spaniel de Pont-Audemer[233]
Caniche[234]
Porcelana[59]
Podengo Português[235]
Ponteiro Português[236]
Cão Pastor Português[237]
Cão de Água Português[238]
Cão de Posavac[239]
Pražský Krysařík[240]
Presa Canário[241]
Ponteiro Pudel[220]
Pug[151]
Puli[238]
Pumi[197]
Pungsan
Mastim dos Pirenéus[242]
Cão da Montanha dos Pirenéus[242]
Cão pastor dos Pirenéus[215]
Rafeiro do Alentejo[243]
Rajapalayam[244]
Galgo Rampur
Rastreador Brasileiro
Rato Terrier[83]
Ratonero Bodeguero Andaluz
Ratonero Murciano
Coonhound Redbone[245]
Ridgeback da Rodésia
Rize Koyun
Cão Pastor Miorítico Romeno[98]
Cão pastor corvo romeno[98]
Rottweiler[165]
Collie áspero[71]
Spaniel Russo
Brinquedo Russo[213][246]
Laika Russo-Europeia[135]
Ryukyu
S–Z
Cão-lobo de Saarloos[120]
Sabueso Espanhol[247]
Cão Boiadeiro de São Miguel[248]
Spaniel de Saint-Usuge
Husky de Sacalina
Saluki[153]
Samoieda[186]
Sapsali
Sarabi[249]
Cão pastor da Sardenha
Šarplaninac[98]
Escapandos[57]
Schillerstövare[131]
Schipperke[250]
Laufhund Suíço[109]
Schweizerischer Niederlaufhund[251]
Deerhound escocês
Terrier Escocês[252]
Sealyham Terrier[123]
Segundo dell'Appennino
Segundo Italiano[247]
Segugio Maremmano
Cão de caça sérvio[253]
Cão Tricolor Sérvio[211]
Bulldog Serrano
Shar Pei
Cão pastor de Shetland[254]
Shiba Inu[255]
Shih Tzu[206]
Shikoku[112]
Pastor Shiloh[240]
Husky Siberiano[168]
Cão de Vento Sedoso
Cão cingalês
Skye Terrier[252]
Sloughi[40]
Ponteiro de pêlo áspero eslovaco[155]
Cuvac Eslovaco[201]
Kopov esloveno[230]
Cão de Smaland[161]
Pequeno Münsterländer[205]
Smithfield
Collie Suave[254]
Fox Terrier Suave[256]
Terrier de trigo com revestimento macio[159]
Ovcharka do sul da Rússia[257]
Mastim Espanhol[214]
Cão de Água Espanhol[178]
Spino degli Iblei
Spinone Italiano[79]
Sporting Lucas Terrier[124]
São Bernardo[204]
Cão de São Hubert Jura
Stabyhoun[130]
Staffordshire Bull Terrier[258]
Schnauzer Padrão[158]
Estoque Stephens[259]
Cão de pêlo grosso da Estíria [20]
Spaniel de Sussex[146]
Lapphund sueco
Vallhund sueco
Taiga[208]
Cachorro Taiwanês
Cachorro Tamaskan
Cachorro Tang
Tarso çatalburun
Cão Pastor Tatra[260]
Tazy
Teddy Roosevelt Terrier
Telomiano
Tenterfield Terrier
Cachorro Bangkaew Tailandês
Ridgeback Tailandês[261]
Kyi Apso tibetano
Mastim Tibetano[262]
Spaniel tibetano[263]
Terrier tibetano[263]
Tonya Finosu
Tornjak[173]
Tosa[264]
Fox Terrier de Brinquedo[74]
Brinquedo Manchester Terrier[143]
Cão da Transilvânia[265]
Arvoredo Cur
Feist de Árvore[266]
Treeing Tennessee Tigrado
Treeing Walker Coonhound[228]
Cão de caça[267]
Cão tirolês[268]
Terrier Valenciano
Vikhan[269]
Villano de Las Encartaciones
Villanuco de Las Encartaciones
Vizsla[270]
Volkosob
Volpino Italiano[232]
Weimaraner[271]
Galês
Cão pastor galês
Springer Spaniel Galês[142]
Terrier Galês[193]
Harrier do Oeste[272]
Terrier Branco das Terras Altas Ocidentais[219]
Laika da Sibéria Ocidental[189]
Dachsbracke da Vestfália[131]
Wetterhoun[233]
Chicote[207]
Pastor Branco
Cão Pastor Suíço Branco[69]
Fox Terrier Arame[256]
Grifo Apontador de Pêlo Arame[273]
Vizsla de pêlo duro
Cachorro Xiasi
Xoloitzcuintle[274]
Laika Yakutiana
Yorkshire Terrier[275]
Zerdava
Sem Raça Definida`;

export var dogBreedList = breeds.split('\n').map(function(line) {
    return line.replace(/\[\d+\]/g, '').trim();
});