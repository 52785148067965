import React from 'react';
import { Accordion, Segment } from 'semantic-ui-react';

const BirdCareDetails = () => {
    const panels = [
        {
            key: 'veterinary-exam',
            title: 'Exame Veterinário Inicial',
            content: {
                content: (
                    <div>
                        <p>Ao adquirir um novo pássaro: Leve ao veterinário especializado em aves para um check-up inicial. Isso pode incluir exames físicos, testes para doenças infecciosas comuns em pássaros e conselhos sobre cuidados adequados.</p>
                    </div>
                ),
            },
        },
        {
            key: 'regular-checkups',
            title: 'Check-ups Regulares',
            content: {
                content: (
                    <div>
                        <p>Anualmente: Exames anuais para verificar a saúde geral, nutrição, e prevenir problemas de saúde.</p>
                    </div>
                ),
            },
        },
        {
            key: 'nutrition',
            title: 'Nutrição Adequada',
            content: {
                content: (
                    <div>
                        <p>Dieta balanceada: Deve ser composta de rações comercialmente preparadas específicas para a espécie, complementadas por uma variedade de frutas e vegetais frescos adequados para aves (evite alimentos tóxicos como abacate, chocolate e cafeína).</p>
                    </div>
                ),
            },
        },
        {
            key: 'environment-maintenance',
            title: 'Manutenção do Ambiente',
            content: {
                content: (
                    <div>
                        <p>Limpeza diária: Limpar a gaiola e os acessórios diariamente para evitar o acúmulo de fezes e restos de comida, o que pode contribuir para o crescimento de bactérias e fungos.</p>
                        <p>Tamanho e estímulo da gaiola: Assegurar que a gaiola seja grande o suficiente para o seu pássaro voar e se exercitar. Adicionar brinquedos e perches para promover atividade física e mental.</p>
                    </div>
                ),
            },
        },
        {
            key: 'parasite-prevention',
            title: 'Prevenção de Parasitas',
            content: {
                content: (
                    <div>
                        <p>Avaliação periódica: Fale com um veterinário sobre a necessidade de tratamento contra parasitas, como ácaros e piolhos, especialmente se o pássaro tem acesso ao exterior ou foi recentemente adquirido.</p>
                    </div>
                ),
            },
        }
    ];

    return (
        <Segment basic>
            <Accordion panels={panels} styled fluid />
        </Segment>
    );
};

export default BirdCareDetails;
