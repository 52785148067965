import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { dogBreedList } from './breeds/dogBreeds';
import { catBreedList } from './breeds/catBreeds';
import { birdBreedList } from './breeds/birdBreeds';
import { rodentBreedList } from './breeds/rodentBreeds';
import { reptileBreedList } from './breeds/reptileBreeds';

//SESSION
export function destroyStorage() {
    localStorage.removeItem('isLogged')
    localStorage.removeItem('_grecaptcha')
    handleReload()
}

export function LogErrors(err) {
    if (process.env.REACT_APP_ENV === 'dev') {
        console.log(err)
    }
};

//TOAST MESSAGES
export function notifySuccess(text) {
    toast.success(text, {
        position: toast.POSITION.TOP_RIGHT
    })
};

export function notifyError(error) {
    toast.error(error, {
        position: toast.POSITION.TOP_RIGHT
    })
};

export function handleReload(millis = 0) {
    const timeoutId = setTimeout(() => {
        window.location.reload()
    }, millis)

    return () => {
        clearTimeout(timeoutId)
    }
};

export function getDogBreeds() {
    let list = []
    let counter = 0
    dogBreedList.forEach(breed => {
        list.push({ key: counter, value: breed, text: breed })
        counter++
    })
    return list
};

export function getCatBreeds() {
    let list = []
    let counter = 0
    catBreedList.forEach(breed => {
        list.push({ key: counter, value: breed, text: breed })
        counter++
    })
    return list
};

export function getBirdBreeds() {
    let list = []
    let counter = 0
    birdBreedList.forEach(breed => {
        list.push({ key: counter, value: breed, text: breed })
        counter++
    })
    return list
};

export function getRodentBreeds() {
    let list = []
    let counter = 0
    rodentBreedList.forEach(breed => {
        list.push({ key: counter, value: breed, text: breed })
        counter++
    })
    return list
};

export function getReptileBreeds() {
    let list = []
    let counter = 0
    reptileBreedList.forEach(breed => {
        list.push({ key: counter, value: breed, text: breed })
        counter++
    })
    return list
};

export function removeId(objectWithId) {
    const { id, ...objectWithoutId } = objectWithId
    return objectWithoutId
};

export function getCurrentDate() {
    const date = new Date()
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
};

export function getCurrentDateTime() {
    const date = new Date()
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const seconds = date.getSeconds().toString().padStart(2, '0')
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
};

export function calculatePetAge(birthDate) {
    const today = new Date();
    const birth = new Date(birthDate);
    let years = today.getFullYear() - birth.getFullYear();
    let months = today.getMonth() - birth.getMonth();
    let days = today.getDate() - birth.getDate();

    if (months < 0 || (months === 0 && days < 0)) {
        years--;
        months = 12 + months; // Adjust for yearly cycle
    }

    // Adjust months if today's date is before the birth date of the current month.
    if (days < 0) {
        months--;
        const lastDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth(), 0).getDate();
        days += lastDayOfPreviousMonth; // Adjust for monthly cycle
    }

    if (years === 0 && months === 0) {
        // Calculate difference in days if both years and months are 0
        const oneDay = 24 * 60 * 60 * 1000; // milliseconds in one day
        const diffInDays = Math.round(Math.abs((birth - today) / oneDay));
        return `${diffInDays} ${diffInDays > 1 ? 'dias' : 'dia'}`;
    }

    let formattedAge = years > 0 ? `${years} ${years === 1 ? 'ano' : 'anos'}` : '';
    if (months > 0) {
        formattedAge += years > 0 ? ` e ${months} ${months > 1 ? 'meses' : 'mês'}` : `${months} ${months > 1 ? 'meses' : 'mês'}`;
    }

    return formattedAge;
};

export function findNameById(objList, targetId) {
    const obj = objList.find(obj => obj.id === targetId);
    return obj ? obj.name : 'Nome não encontrado';
};

export function findObjById(objList, targetId) {
    const obj = objList.find(obj => obj.id === targetId);
    return obj ? obj : null;
};

export function findTextByKey(objList, targetKey) {
    const obj = objList.find(obj => obj.key === targetKey);
    return obj ? obj.text : 'Texto não encontrado';
};

export function sortObjectsByProperty(list, property, order = 'asc') {
    return list.sort((a, b) => {
        if (a[property] < b[property]) {
            return order === 'asc' ? -1 : 1;
        } else if (a[property] > b[property]) {
            return order === 'asc' ? 1 : -1;
        }
        return 0;
    });
};

export function getCurrentMonthCareEvents() {
    const currentMonth = new Date().getMonth() + 1;  // JavaScript months are 0-based, so add 1
    return petCareSchedule.filter(item =>
        item.startDate <= currentMonth && item.endDate >= currentMonth
    );
}

export function isDateWithinDaysOrToday(dateString, days) {
    const inputDate = new Date(dateString);

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Zera as horas para comparação apenas da data

    const differenceInTime = inputDate.getTime() - today.getTime();

    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    if (differenceInDays === 0) {
        return 'equal';
    } else if (differenceInDays > 0 && differenceInDays <= days) {
        return 'within';
    } else {
        return 'far';
    }
}

export function getWarningColor(dateString, days = 10) {
    let descriptionStyle;
    const dateStatus = isDateWithinDaysOrToday(dateString, days)
    if (dateStatus === 'equal') {
        descriptionStyle = { color: 'red', fontWeight: 'bold' };
    } else if (dateStatus === 'within') {
        descriptionStyle = { color: 'orange', fontWeight: 'bold' };
    } else {
        descriptionStyle = {};
    }
    return descriptionStyle;
}

const petCareSchedule = [
    {
        startDate: "1",
        endDate: "3",
        title: "Cuidados de Inverno",
        description: "Frio intenso: Em áreas com invernos rigorosos, proteja os pets do frio extremo e de produtos como sal e químicos usados para derreter gelo, que podem ser irritantes ou tóxicos. Risco de intoxicação por anticongelante: Mantenha produtos de anticongelante longe dos animais."
    },
    {
        startDate: "4",
        endDate: "6",
        title: "Alergias de Primavera",
        description: "Aumento dos níveis de pólen: Com o início da primavera, o pólen pode causar alergias em pets. Monitorar sintomas como espirros, coceira e irritação ocular."
    },
    {
        startDate: "4",
        endDate: "6",
        title: "Prevenção de Parasitas",
        description: "Prevenção de parasitas: Inicie ou continue o tratamento contra pulgas, carrapatos e mosquitos, que se tornam mais ativos nesse período."
    },
    {
        startDate: "6",
        endDate: "9",
        title: "Ondas de Calor e Cuidados com Águas Estagnadas",
        description: "Ondas de calor: Certifique-se de que os pets tenham sombra e água fresca disponível, e evite exercícios intensos nos períodos mais quentes do dia. Águas estagnadas: Fique atento a águas paradas que podem ser focos para a reprodução de mosquitos."
    },
    {
        startDate: "10",
        endDate: "12",
        title: "Produtos de Controle de Pragas e Festividades",
        description: "Produtos para controle de pragas: Aumento do uso de rodenticidas e inseticidas pode representar riscos de intoxicação. Folhas caídas e mofo: Mantenha o ambiente limpo para evitar a exposição a fungos nocivos. Festas e fogos de artifício: Durante festividades, como o Halloween e o Ano Novo, esteja ciente de que o barulho dos fogos de artifício pode causar ansiedade em muitos pets."
    },
    {
        startDate: "3",
        endDate: "6",
        title: "Lagarta do Pinheiro",
        description: "Lagarta do Pinheiro (Processionária): Em regiões onde essas lagartas são comuns, geralmente nos meses mais quentes do início da primavera, evite áreas com pinheiros durante as 'procissões' das lagartas para prevenir contato que pode ser muito perigoso."
    },
    {
        startDate: "10",
        endDate: "12",
        title: "Aumento na atividade de répteis",
        description: "Atividade de répteis: As primeiras chuvas de outono podem de fato estimular a atividade de répteis em muitas regiões, o que pode requer atenção redobrada para potenciais repteis venenosos."
    }
];

export function formatCities() {
    let citiesOptions = [];
    let counter = 0;
    cities.forEach(city => {
        citiesOptions.push({
            key: counter,
            text: city,
            value: city
        });
        counter++;
    });
    return citiesOptions;
}

const cities = [
    "Abrantes",
    "Agualva-Cacém",
    "Águeda",
    "Albergaria-a-Velha",
    "Albufeira",
    "Alcácer do Sal",
    "Alcobaça",
    "Alfena",
    "Almada",
    "Almeirim",
    "Alverca do Ribatejo",
    "Amadora",
    "Amarante",
    "Amora",
    "Anadia",
    "Aveiro",
    "Barcelos",
    "Barreiro",
    "Beja",
    "Borba",
    "Braga",
    "Bragança",
    "Caldas da Rainha",
    "Cantanhede",
    "Cartaxo",
    "Castelo Branco",
    "Chaves",
    "Coimbra",
    "Costa da Caparica",
    "Covilhã",
    "Elvas",
    "Entroncamento",
    "Ermesinde",
    "Esmoriz",
    "Espinho",
    "Esposende",
    "Estarreja",
    "Estremoz",
    "Évora",
    "Fafe",
    "Faro",
    "Fátima",
    "Felgueiras",
    "Figueira da Foz",
    "Fiães",
    "Freamunde",
    "Fundão",
    "Gafanha da Nazaré",
    "Gandra",
    "Gondomar",
    "Gouveia",
    "Guarda",
    "Guimarães",
    "Ílhavo",
    "Lagoa",
    "Lagos",
    "Lamego",
    "Leiria",
    "Lisboa",
    "Lixa",
    "Lordelo",
    "Loulé",
    "Loures",
    "Lourosa",
    "Macedo de Cavaleiros",
    "Maia",
    "Mangualde",
    "Marco de Canaveses",
    "Marinha Grande",
    "Matosinhos",
    "Mealhada",
    "Mêda",
    "Miranda do Douro",
    "Mirandela",
    "Montemor-o-Novo",
    "Montijo",
    "Moura",
    "Odivelas",
    "Olhão",
    "Oliveira de Azeméis",
    "Oliveira do Bairro",
    "Oliveira do Hospital",
    "Ourém",
    "Ovar",
    "Paços de Ferreira",
    "Paredes",
    "Penafiel",
    "Peniche",
    "Peso da Régua",
    "Pinhel",
    "Pombal",
    "Ponte de Sor",
    "Portalegre",
    "Portimão",
    "Porto",
    "Póvoa de Santa Iria",
    "Póvoa de Varzim",
    "Quarteira",
    "Queluz",
    "Rebordosa",
    "Reguengos de Monsaraz",
    "Rio Maior",
    "Rio Tinto",
    "Sabugal",
    "Sacavém",
    "Samora Correia",
    "Santa Comba Dão",
    "Santa Maria da Feira",
    "Santarém",
    "Santiago do Cacém",
    "Santo Tirso",
    "São João da Madeira",
    "São Mamede de Infesta",
    "São Pedro do Sul",
    "Seia",
    "Seixal",
    "Senhora da Hora",
    "Serpa",
    "Setúbal",
    "Silves",
    "Sines",
    "Tarouca",
    "Tavira",
    "Tomar",
    "Tondela",
    "Torres Novas",
    "Torres Vedras",
    "Trancoso",
    "Trofa",
    "Valbom",
    "Vale de Cambra",
    "Valença",
    "Valongo",
    "Valpaços",
    "Vendas Novas",
    "Viana do Castelo",
    "Vila do Conde",
    "Vila Franca de Xira",
    "Vila Nova de Famalicão",
    "Vila Nova de Foz Côa",
    "Vila Nova de Gaia",
    "Vila Nova de Santo André",
    "Vila Real",
    "Vila Real de Santo António",
    "Viseu",
    "Vizela"
];