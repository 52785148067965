import React, { useState } from 'react';
import { Button, Grid, Icon, Modal, Segment } from 'semantic-ui-react';
import { deleteFiles, uploadFiles } from '../../resources/storageLib';
import { notifyError, notifySuccess } from '../../resources/lib';
import { useAuth } from '../account/AuthContext';
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from 'react-image-crop';

const ASPECT_RATIO = 1;
const MIN_DIMENSION = 238;

const PetPhoto = ({ pet, files, reload, reloadPets }) => {
    const { user } = useAuth();
    const [hasChanges, setHasChanges] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState(files);
    const [imageSrc, setImageSrc] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [crop, setCrop] = useState();

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e, index) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file) {
            const updatedFiles = [...selectedFiles];
            updatedFiles[index] = file;
            setSelectedFiles(updatedFiles);
            setHasChanges(true);
        }
    };

    const handleFileInputChange = async (e, index) => {
        e.preventDefault();
        const file = e.target.files[0];
        if (file) {
            await onFileChange(e);
        }
    };

    const handleRemoveImage = (e, index) => {
        e.preventDefault();
        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1);
        const filteredFiles = updatedFiles.filter((file) => file !== null);
        filteredFiles.push(null);
        setSelectedFiles(filteredFiles);
        setHasChanges(true);
    };

    const onFileChange = async (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.addEventListener("load", (e) => {
                const { naturalWidth, naturalHeight } = e.currentTarget;
                if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
                    notifyError("A imagem tem de ter no mínimo 238px de largura e altura.");
                    setModalOpen(false);
                    return;
                }
                const imageUrl = reader.result?.toString() || "";
                setImageSrc(imageUrl);
            });
            reader.readAsDataURL(file);
        }
        setModalOpen(true);
    };

    const onImageLoad = (e) => {
        const { width, height } = e.currentTarget;
        const cropWidthInPercent = (MIN_DIMENSION / width) * 100;
        const crop = makeAspectCrop(
            {
                unit: "%",
                width: cropWidthInPercent,
            },
            ASPECT_RATIO,
            width,
            height
        );
        const centeredCrop = centerCrop(crop, width, height);
        setCrop(centeredCrop);
    };

    const getCroppedImg = (image, crop) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext("2d");
        if (!ctx) {
            throw new Error("No 2d context");
        }

        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

        ctx.scale(pixelRatio, pixelRatio);
        ctx.imageSmoothingQuality = "high";
        ctx.save();

        const cropX = crop.x * scaleX;
        const cropY = crop.y * scaleY;

        ctx.translate(-cropX, -cropY);
        ctx.drawImage(
            image,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight
        );

        ctx.restore();

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = 'newFile.jpeg';
                resolve(blob);
            }, 'image/jpeg');
        });
    };

    const handleSaveCrop = async (index) => {
        if (!crop || !imageSrc) {
            console.error('No crop selection');
            return;
        }

        const image = new Image();
        image.src = imageSrc;
        image.onload = async () => {
            const croppedImageBlob = await getCroppedImg(image, convertToPixelCrop(crop, image.width, image.height));
            const croppedFile = new File([croppedImageBlob], 'croppedImage.jpeg', {
                type: 'image/jpeg'
            });
            const updatedFiles = [...selectedFiles];
            updatedFiles[index] = croppedFile;
            setSelectedFiles(updatedFiles);
            setHasChanges(true);
            handleCloseModal();
        };
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleSaveImages = async (e) => {
        e.preventDefault();
        if (hasChanges) {
            if (selectedFiles[0] === null) {
                const result = await deleteFiles(files, `photos/${pet.id}`);
                if (result) {
                    notifySuccess('Foto removida com sucesso.');
                    reloadPets(user);
                    reload(pet);
                }
                else {
                    notifyError('Falha ao remover a sua Foto.');
                }
            }
            else {
                let result = await deleteFiles(files, `photos/${pet.id}`);
                if (result) {
                    result = await uploadFiles(selectedFiles, `photos/${pet.id}`);
                    if (result) {
                        notifySuccess('Foto adicionada com sucesso.');
                        reloadPets(user);
                        reload(pet);
                    }
                    else {
                        notifyError('Falha ao adicionar a sua Foto.');
                    }
                }
                else {
                    notifyError('Falha ao remover a sua Foto.');
                }
            }
        }
    };

    const showButton = (index) => (
        <Button
            type='button'
            circular
            icon
            inverted
            onClick={e => handleRemoveImage(e, index)}
            color='red'
        >
            <Icon name='delete' />
        </Button>
    );

    return (
        <Grid columns={1}>
            {selectedFiles.map((file, index) => (
                <Grid.Column key={index}>
                    <Segment
                        compact
                        basic
                        onDragOver={(e) => handleDragOver(e, index)}
                        onDrop={(e) => handleDrop(e, index)}
                        textAlign="center"
                    >
                        <input
                            id={`fileInput${index}`}
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={(e) => handleFileInputChange(e, index)}
                            disabled={file !== null}
                        />
                        <Modal open={modalOpen} onClose={handleCloseModal}>
                            <Modal.Header>Crop your image</Modal.Header>
                            <Modal.Content image>
                                {imageSrc && (
                                    <ReactCrop
                                        crop={crop}
                                        onChange={(pixelCrop, percentageCrop) => setCrop(percentageCrop)}
                                        keepSelection
                                        aspect={ASPECT_RATIO}
                                        maxHeight={MIN_DIMENSION}
                                        maxWidth={MIN_DIMENSION}
                                    >
                                        <img
                                            src={imageSrc}
                                            alt='Upload'
                                            style={{ maxHeight: '500px', maxWidth: '500px', margin: 'auto' }}
                                            onLoad={onImageLoad}
                                        />
                                    </ReactCrop>
                                )}
                            </Modal.Content>
                            <Modal.Actions>
                                <Button onClick={handleCloseModal} color="red">Cancel</Button>
                                <Button onClick={() => handleSaveCrop(index)} color="green">Save Crop</Button>
                            </Modal.Actions>
                        </Modal>
                        <div
                            onClick={() => document.getElementById(`fileInput${index}`).click()}
                            onDragEnter={(e) => e.preventDefault()}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={(e) => e.preventDefault()}
                            style={{
                                width: '100%',
                                height: '200px',
                                border: '2px dashed #ccc',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                backgroundColor: 'white',
                                margin: file ? '0px' : '10px'
                            }}
                        >
                            {file ? (
                                <img
                                    src={file.isStorage != null ? file.url : URL.createObjectURL(file)}
                                    alt=""
                                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                                />
                            ) : (
                                <>
                                    <p>Arraste e solte ou clique para</p>
                                    <p>carregar uma foto do Pet</p>
                                </>
                            )}
                        </div>
                        <br />
                        {file != null && showButton(index)}
                    </Segment>
                    <Button
                        type='button'
                        color='green'
                        onClick={handleSaveImages}
                        disabled={!hasChanges}
                    >
                        Gravar Foto
                    </Button>
                </Grid.Column>
            ))}
        </Grid>
    );
};

export default PetPhoto;