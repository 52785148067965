import React, { useEffect, useState } from 'react';
import { Card, Image, Icon } from 'semantic-ui-react';
import photo from '../../../../resources/avatars/reptile.png'
import ConfimationModal from '../../../modals/ConfimationModal';
import { updateDataByDocId } from '../../../../resources/firebaseLib';
import { calculatePetAge, notifyError, notifySuccess, removeId } from '../../../../resources/lib';
import { reptilesCollection } from '../../../../resources/const/firebaseCollections';
import { useAuth } from '../../../account/AuthContext';
import EditPetModal from '../../../modals/EditPetModal';
import PetProfilePreviewModal from '../../../modals/PetProfilePreviewModal';
import { getAllFilesFromFolder } from '../../../../resources/storageLib';

const ReptileCard = ({ pet, reload }) => {
    const { user } = useAuth();
    const [hover, setHover] = useState(false);
    const [lock, setLock] = useState(false);
    const [petPhoto, setPetPhoto] = useState(null);

    useEffect(() => {
        getPetPhotos(pet);
    }, [pet]);

    async function getPetPhotos(pet) {
        const data = await getAllFilesFromFolder(`photos/${pet.id}`);
        if (data.length > 0)
            setPetPhoto(data[0].url);
    };

    const handleHover = (value) => {
        if (!lock)
            setHover(value);
    }

    const handleHoverModal = (value) => {
        setLock(value);
        setHover(value);
    }

    const handleModal = async (value) => {
        if (value) {
            pet.isDelete = 1;
            const result = await updateDataByDocId(reptilesCollection, pet.id, removeId(pet));
            if (result) {
                reload(user);
                notifySuccess(`O seu pet ${pet.name}, foi apagado.`);
            }
            else {
                notifyError(`Falha a apagar este pet: ${pet.name}`);
            }
        }
    };

    return (
        <Card
            className='primary-color'
            onMouseEnter={() => handleHover(true)}
            onMouseLeave={() => handleHover(false)}
            style={{ margin: 'auto' }}
        >
            <Image src={petPhoto == null ? photo : petPhoto} wrapped ui={false} className='tertiary-color image-div' />
            <Card.Content>
                <Card.Header>{pet.name}</Card.Header>
                <Card.Meta>
                    <span className='date'>{calculatePetAge(pet.birthDate)}</span>
                </Card.Meta>
                <Card.Content extra>
                    <Icon name={pet.sex === 'male' ? 'man' : 'woman'} />
                    {pet.species}
                </Card.Content>
            </Card.Content>
            {hover && (
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    display: 'flex',
                    justifyContent: 'space-around',
                    width: '100%',
                }}>
                    <PetProfilePreviewModal pet={pet} petPhoto={petPhoto} trigger={<Icon name='eye' size='large' />} action={handleHoverModal} />
                    <EditPetModal pet={pet} trigger={<Icon name='edit' size='large' />} reload={reload} action={handleHoverModal} />
                    <ConfimationModal trigger={<Icon name='delete' size='large' />} action={handleModal} />
                </div>
            )}
        </Card>
    )
}

export default ReptileCard;