import React, { useState } from 'react';
import { Button, Modal, Menu, Segment, Dimmer, Loader } from 'semantic-ui-react';
import DogForm from '../petForms/DogForm';
import CatForm from '../petForms/CatForm';
import RodentForm from '../petForms/RodentForm';
import BirdForm from '../petForms/BirdForm';
import ReptileForm from '../petForms/ReptileForm';
import VeterinarianList from '../vet/VeterinariansList';
import PetChipForm from '../vet/forms/PetChipForm';
import VaccinationList from '../vet/VaccinationList';
import DewormingList from '../vet/DewormingList';
import MedicationList from '../vet/MedicationList';
import SurgeryList from '../vet/SurgeryList';
import MeasurementsList from '../vet/MeasurementsList';
import { chipsCollection, dewormingsCollection, measurementsCollection, medicationsCollection, surgeriesCollection, vaccinesCollection, vetsCollection } from '../../resources/const/firebaseCollections';
import { getDataBy } from '../../resources/firebaseLib';
import { sortObjectsByProperty } from '../../resources/lib';
import PetPhoto from '../vet/PetPhoto';
import { getAllFilesFromFolder } from '../../resources/storageLib';

const EditPetModal = ({ pet, trigger: Trigger, reload, action }) => {
  const [open, setOpen] = useState(false);
  const [onLoad, setOnLoad] = useState(false);
  const [activeItem, setActiveItem] = useState('profile');

  const [veterinarians, setVeterinarians] = useState([]);
  const [vaccines, setVaccines] = useState([]);
  const [dewormings, setDewormings] = useState([]);
  const [medications, setMedications] = useState([]);
  const [surgeries, setSurgeries] = useState([]);
  const [measurements, setMeasurements] = useState([]);
  const [chip, setChip] = useState([]);
  const [petPhotos, setPetPhotos] = useState([]);

  async function getVets(pet) {
    const data = await getDataBy(vetsCollection, 'uid', pet.id);
    setVeterinarians(sortObjectsByProperty(data, 'creationDate', 'desc'));
  };

  async function getVaccines(pet) {
    const data = await getDataBy(vaccinesCollection, 'uid', pet.id);
    setVaccines(sortObjectsByProperty(data, 'givenDate', 'desc'));
  };

  async function getDewormings(pet) {
    const data = await getDataBy(dewormingsCollection, 'uid', pet.id);
    setDewormings(sortObjectsByProperty(data, 'creationDate', 'desc'));
  };

  async function getMedications(pet) {
    const data = await getDataBy(medicationsCollection, 'uid', pet.id);
    setMedications(sortObjectsByProperty(data, 'creationDate', 'desc'));
  };

  async function getSurgeries(pet) {
    const data = await getDataBy(surgeriesCollection, 'uid', pet.id);
    setSurgeries(sortObjectsByProperty(data, 'creationDate', 'desc'));
  };

  async function getMeasurements(pet) {
    const data = await getDataBy(measurementsCollection, 'uid', pet.id);
    setMeasurements(sortObjectsByProperty(data, 'creationDate', 'desc'));
  };

  async function getChip(pet) {
    const data = await getDataBy(chipsCollection, 'uid', pet.id);
    setChip(data);
  };

  async function getPetPhotos(pet) {
    const data = await getAllFilesFromFolder(`photos/${pet.id}`);
    if(data.length < 1)
      data.push(null);
    setPetPhotos(data);
  };

  const handleOpen = () => {
    setOpen(true);
    action(true);
  };
  const handleClose = () => {
    setOpen(false);
    action(false);
  };

  const handleSetActive = async (value) => {
    setOnLoad(true);
    setActiveItem(value);
    if (value !== 'profile' && veterinarians.length === 0) {
      await getVets(pet);
      await getVaccines(pet);
      await getDewormings(pet);
      await getMedications(pet);
      await getSurgeries(pet);
      await getMeasurements(pet);
      await getChip(pet);
      await getPetPhotos(pet);
    }
    setOnLoad(false);
  };

  const renderForm = (pet) => {
    switch (pet.type) {
      case 'dog': return <DogForm pet={pet} reload={reload} closeModal={handleClose} />;
      case 'cat': return <CatForm pet={pet} reload={reload} closeModal={handleClose} />;
      case 'rodent': return <RodentForm pet={pet} reload={reload} closeModal={handleClose} />;
      case 'bird': return <BirdForm pet={pet} reload={reload} closeModal={handleClose} />;
      case 'reptile': return <ReptileForm pet={pet} reload={reload} closeModal={handleClose} />;
      default: return <div></div>;
    }
  };

  return (
    <Modal
      trigger={<div onClick={handleOpen}>{Trigger}</div>}
      open={open}
      onClose={handleClose}
      size='large'
    >
      <Modal.Header className='primary-color'>Editar Informações do Pet</Modal.Header>
      <Modal.Content scrolling>
        <Menu attached="top" tabular stackable>
          <Menu.Item
            name="profile"
            active={activeItem === 'profile'}
            onClick={() => handleSetActive('profile')}
          >
            Perfil do Pet
          </Menu.Item>
          <Menu.Item
            name="vet"
            active={activeItem === 'vet'}
            onClick={() => handleSetActive('vet')}
          >
            Informação do Veterinário
          </Menu.Item>
          <Menu.Item
            name="vaccination"
            active={activeItem === 'vaccination'}
            onClick={() => handleSetActive('vaccination')}
          >
            Vacinação
          </Menu.Item>
          <Menu.Item
            name="deworming"
            active={activeItem === 'deworming'}
            onClick={() => handleSetActive('deworming')}
          >
            Desparasitação
          </Menu.Item>
          <Menu.Item
            name="medications"
            active={activeItem === 'medications'}
            onClick={() => handleSetActive('medications')}
          >
            Medicação
          </Menu.Item>
          <Menu.Item
            name="surgeries"
            active={activeItem === 'surgeries'}
            onClick={() => handleSetActive('surgeries')}
          >
            Cirurgias
          </Menu.Item>
          <Menu.Item
            name="measurements"
            active={activeItem === 'measurements'}
            onClick={() => handleSetActive('measurements')}
          >
            Pesagens
          </Menu.Item>
          {(pet.type === 'dog' || pet.type === 'cat') &&
            <Menu.Item
              name="chip"
              active={activeItem === 'chip'}
              onClick={() => handleSetActive('chip')}
            >
              Chip
            </Menu.Item>
          }
          <Menu.Item
            name="photo"
            active={activeItem === 'photo'}
            onClick={() => handleSetActive('photo')}
          >
            Foto
          </Menu.Item>
        </Menu>

        {onLoad &&
          <Segment attached="bottom" style={{ minHeight: '100px' }}>
            <Dimmer active style={{ backgroundColor: 'transparent' }}>
              <Loader indeterminate size='massive' style={{ color: 'black' }}>Carregando...</Loader>
            </Dimmer>
          </Segment>
        }

        {!onLoad &&
          <Segment attached="bottom">
            {activeItem === 'profile' && <div>{renderForm(pet)}</div>}
            {activeItem === 'vet' && <div><VeterinarianList pet={pet} veterinarians={veterinarians} reload={getVets} /></div>}
            {activeItem === 'vaccination' && <div><VaccinationList pet={pet} vaccines={vaccines} veterinarians={veterinarians} reload={getVaccines} /></div>}
            {activeItem === 'deworming' && <div><DewormingList pet={pet} dewormings={dewormings} reload={getDewormings} /></div>}
            {activeItem === 'medications' && <div><MedicationList pet={pet} medications={medications} veterinarians={veterinarians} reload={getMedications} /></div>}
            {activeItem === 'surgeries' && <div><SurgeryList pet={pet} surgeries={surgeries} veterinarians={veterinarians} reload={getSurgeries} /></div>}
            {activeItem === 'measurements' && <div><MeasurementsList pet={pet} measurements={measurements} reload={getMeasurements} /></div>}
            {activeItem === 'chip' && <div><PetChipForm pet={pet} chip={chip} reload={getChip} /></div>}
            {activeItem === 'photo' && <div><PetPhoto pet={pet} files={petPhotos} reload={getPetPhotos} reloadPets={reload} /></div>}
          </Segment>
        }
      </Modal.Content>
      <Modal.Actions className='tertiary-color'>
        <Button negative onClick={() => handleClose(false)}>Fechar</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default EditPetModal;

