import React, { useEffect, useState } from 'react';
import { getCurrentMonthCareEvents } from '../../../../resources/lib';
import { Card, Icon, Header, Container } from 'semantic-ui-react';

const SeasonalCareAlert = () => {
    const [careItems, setCareItems] = useState([]);

    useEffect(() => {
        const items = getCurrentMonthCareEvents();
        setCareItems(items);
    }, []);

    const formatDescription = (text) => {
        let parts = text.split(':');
        return (
            <p><strong>{parts[0]}:</strong> {parts[1]}</p>
        )
    };

    if (careItems.length === 0) {
        return null;
    }

    return (
        <Container style={{ margin: '10px', padding: '20px', backgroundColor: '#fffae6', borderRadius: '8px', border: '1px solid #ffc107' }}>
            <Header as='h2' icon textAlign='center'>
                <Icon name='exclamation triangle' circular style={{ color: '#ff9900' }} />
                <Header.Content>Cuidados Sazonais para o seu Pet</Header.Content>
                <Header.Subheader>Atenção aos perigos e cuidados específicos desta altura do ano!</Header.Subheader>
            </Header>
            <Card.Group itemsPerRow={careItems.length > 1 ? 2 : 1}>
                {careItems.map((item, index) => (
                    <Card key={index} color='orange'>
                        <Card.Content>
                            <Card.Header>{item.title}</Card.Header>
                            <Card.Description>{formatDescription(item.description)}</Card.Description>
                        </Card.Content>
                    </Card>
                ))}
            </Card.Group>
        </Container>
    );
};

export default SeasonalCareAlert;
