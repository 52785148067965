import React from 'react';
import { Icon, Image, Modal } from 'semantic-ui-react';
import { calculatePetAge, findTextByKey } from '../../../../resources/lib';
import photo from '../../../../resources/avatars/cat.png'
import { catFurTypes } from '../../../../resources/const/dropdownOptions';

const CatProfilePreview = ({ pet, petPhoto, measurements }) => {
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Image wrapped size="medium" src={petPhoto == null ? photo : petPhoto} alt="Foto do Pet" circular style={{ marginTop: '10px' }} />
            </div>
            <Modal.Description style={{ textAlign: 'center' }}>
                <p style={{ margin: '0px' }}><Icon name={pet.sex === 'male' ? 'man' : 'woman'} /> {pet.breed}</p>
                <p style={{ margin: '0px' }}>{calculatePetAge(pet.birthDate)}</p>
                <p style={{ margin: '0px' }}>{findTextByKey(catFurTypes, pet.coatType)} - {pet.coatColor}</p>
                {measurements.length > 0 &&
                    <p style={{ margin: '0px' }}>{measurements[0].weight} kg</p>
                }
            </Modal.Description>
        </>
    );
};

export default CatProfilePreview;