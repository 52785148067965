import React from 'react';
import { Segment, Header, Image, Grid, Divider } from 'semantic-ui-react';
import photo from '../../../../resources/other/eureka.png'

const CompanyHistory = () => (
  <Segment inverted style={{ padding: '2em 0em' }} vertical textAlign='center'>
    <Header className='step-title' as='h3' style={{ fontSize: '2em', textAlign: 'center' }}>
      A Nossa História
    </Header>
    <Divider style={{ maxWidth: '80%', margin: 'auto' }} />
    <br />
    <Grid container stackable verticalAlign='middle'>
      <Grid.Row>
        <Grid.Column width={8}>
          <p style={{ fontSize: '1.33em' }}>
            Tudo começou com a gestão da saúde dos meus dois cães – um desafio que me fez perceber a falta de uma solução centralizada e acessível. Assim, nasceu o <strong className='step-title'>myPET.ID</strong>, inspirado pela necessidade real e pessoal de simplificar o cuidado dos nossos companheiros de quatro patas.
          </p>
        </Grid.Column>
        <Grid.Column width={8}>
          <Image circular centered size='large' src={photo} alt="História do myPET.ID" style={{ border: '5px solid #51cb61', padding: '4px', backgroundColor: '#fff' }} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <p style={{ fontSize: '1.33em', textAlign: 'center' }}>
            Transformar essa visão em realidade não foi um caminho fácil. Cada obstáculo enfrentado no desenvolvimento do <strong className='step-title'>myPET.ID</strong> reforçou a nossa determinação em criar não apenas um software, mas uma ferramenta essencial para os donos de animais. Estamos dedicados a melhorar continuamente, garantindo que o <strong className='step-title'>myPET.ID</strong> atenda e supere as necessidades da nossa crescente comunidade.
          </p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
);

export default CompanyHistory;

