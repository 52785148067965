const breeds = `Porquinho da índia
Rato
Ratazana
Hamster
Hamster, anão
Gerbil
Degu
Chinchila`;

export var rodentBreedList = breeds.split('\n').map(function(line) {
    return line.replace(/\[\d+\]/g, '').trim();
});