import React, { useState, cloneElement } from 'react';
import { Modal, Button } from 'semantic-ui-react';

const CreationModals = ({ title, trigger: Trigger, children }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  const childrenWithProps = cloneElement(children, { closeModal: handleClose });

  return (
    <Modal
      trigger={<div onClick={handleOpen}>{Trigger}</div>}
      open={open}
      onClose={handleClose}
      size='small'
    >
      <Modal.Header className='primary-color'>{title}</Modal.Header>
      <Modal.Content scrolling style={{miHeight: '300px'}}>
        {childrenWithProps}
      </Modal.Content>
      <Modal.Actions className='tertiary-color'>
        <Button onClick={handleClose} negative>
          Fechar
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CreationModals;


