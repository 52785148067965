const breeds = `Passarinho-zebra australiano
Pomba berbere
Canário doméstico
Calopsita
Avestruz comum
Ema de Darwin
Pato doméstico
Ganso doméstico
Pintada doméstica
Pato Moscóvia Doméstico
Pombo doméstico
Codorna domesticada
Emu
Frango selvagem
Pombo selvagem
tentilhão de Gouldian
Ema maior
Codorna japonesa
Calopsita Lutino
Pato almiscarado
Calopsita malhada
Reia
Pomba da rocha
Passarinho da sociedade
tentilhão estrela
Peru doméstico
Calopsita de cara branca`;

export var birdBreedList = breeds.split('\n').map(function(line) {
    return line.replace(/\[\d+\]/g, '').trim();
});