import React, { useState } from 'react';
import { Modal, Button, Dropdown } from 'semantic-ui-react';
import DogForm from '../petForms/DogForm';
import CatForm from '../petForms/CatForm';
import RodentForm from '../petForms/RodentForm';
import BirdForm from '../petForms/BirdForm';
import ReptileForm from '../petForms/ReptileForm';

const AnimalModal = ({ title, trigger: Trigger, reload }) => {
  const [open, setOpen] = useState(false);
  const [selectedPet, setSelectedPet] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSelectedPet('');
    setOpen(false);
  };

  const petOptions = [
    { key: 'dog', value: 'dog', text: 'Cão' },
    { key: 'cat', value: 'cat', text: 'Gato' },
    { key: 'rodent', value: 'rodent', text: 'Roedor' },
    { key: 'bird', value: 'bird', text: 'Ave' },
    { key: 'reptile', value: 'reptile', text: 'Réptil' }
  ];

  const handlePetChange = (e, { value }) => setSelectedPet(value);

  const renderForm = () => {
    switch (selectedPet) {
      case 'dog': return <DogForm reload={reload} closeModal={handleClose} />;
      case 'cat': return <CatForm reload={reload} closeModal={handleClose} />;
      case 'rodent': return <RodentForm reload={reload} closeModal={handleClose} />;
      case 'bird': return <BirdForm reload={reload} closeModal={handleClose} />;
      case 'reptile': return <ReptileForm reload={reload} closeModal={handleClose} />;
      default: return <div></div>;
    }
  };

  return (
    <Modal
      trigger={<div onClick={handleOpen}>{Trigger}</div>}
      open={open}
      onClose={handleClose}
      size='small'
    >
      <Modal.Header className='primary-color'>{title}</Modal.Header>
      <Modal.Content style={{minHeight: '300px'}}>
        <label>Tipo de Pet</label>
        <Dropdown
          placeholder='Selecione o tipo de pet'
          fluid
          selection
          options={petOptions}
          onChange={handlePetChange}
        />
        <br />
        {renderForm()}
      </Modal.Content>
      <Modal.Actions className='tertiary-color'>
        <Button onClick={handleClose} negative>
          Fechar
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AnimalModal;

