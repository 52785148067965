import { useEffect, useState } from "react";
import { Button, Form, Header, Icon, Loader, Modal, Segment } from "semantic-ui-react";
import { LogErrors, getCurrentDateTime, notifyError, removeId } from "../../resources/lib";
import ReactMarkdown from 'react-markdown';
import { useAuth } from "../account/AuthContext";
import { messageCounterCollection } from "../../resources/const/firebaseCollections";
import { addDataToCollection, getDataBy2Wheres, updateDataByDocId } from "../../resources/firebaseLib";
import { OPENAI_KEY } from "../../resources/const/constants";

const contextBot = `You are the support that know everything about myPET.ID.
Answer in Portuguese from Portugal language everytime.`;

const SupportChatModal = ({ isOpen, closeModal }) => {
    const { user } = useAuth();
    const [onLoad, setOnLoad] = useState(false);
    const [messageToSend, setMessage] = useState('');
    const [messageCounterInitialState, setMessageCounterInitialState] = useState(0);
    const [messageCounterObj, setMessageCounterObj] = useState(null);
    const [messageCounter, setMessageCounter] = useState(0);
    const [messages, setMessages] = useState([
        {
            message: "Olá, eu sou o Patitas! Como posso ajudar?",
            sentTime: getCurrentDateTime(),
            sender: "ChatGPT",
        },
    ]);

    useEffect(() => {
        async function fetchData() {
            await getMessageCounter(user);
        }
        fetchData();
    }, [user]);

    async function getMessageCounter(user) {
        setOnLoad(true);
        const data = await getDataBy2Wheres(messageCounterCollection, 'uid', 'date', user.uid, new Date().toISOString().slice(0, 10));
        setMessageCounter(data.length > 0 ? data[0].value : 0);
        setMessageCounterInitialState(data.length > 0 ? data[0].value : 0);
        setMessageCounterObj(data.length > 0 ? data[0] : null);
        setOnLoad(false);
    };

    const [isTyping, setIsTyping] = useState(false);

    const handleChange = (e) => setMessage(e.target.value);

    const handleSubmit = async () => {
        if (!messageToSend.trim()) return;

        if (messageCounter > 15) {
            notifyError("Atingiu o limite de 15 mensagens por dia. Se ainda não conseguiu resolver as suas dúvidas contacte o suporte.");
            return;
        }
        await handleSendRequest(messageToSend);
        setMessageCounter(messageCounter + 1);
    };

    const handleCloseModal = async () => {
        await updateMessageCount();
        await getMessageCounter(user);
        closeModal();
    }

    async function populateCollection(uid) {
        try {
            const addData = {
                uid: uid,
                value: messageCounter,
                date: new Date().toISOString().slice(0, 10)
            };

            return await addDataToCollection(messageCounterCollection, addData);
        } catch (error) {
            LogErrors(error);
            return false;
        }
    };

    const updateMessageCount = async () => {
        if (messageCounterInitialState === 0) {
            const result = await populateCollection(user.uid);
            if (result) {
                closeModal();
            }
            else {
                notifyError('Falha ao atualizar o counter de mensagens do assistente.');
                closeModal();
            }
        }
        else {
            messageCounterObj.value = messageCounter;
            const result = await updateDataByDocId(messageCounterCollection, messageCounterObj.id, removeId(messageCounterObj));
            if (result) {
                closeModal();
            }
            else {
                notifyError('Falha ao atualizar o counter de mensagens do assistente.');
                closeModal();
            }
        }
    };

    const handleSendRequest = async (message) => {
        const newMessage = {
            message,
            direction: 'outgoing',
            sentTime: getCurrentDateTime(),
            sender: "user",
        };

        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setIsTyping(true);

        try {
            const response = await processMessageToChatGPT([...messages, newMessage]);
            const content = response.choices[0]?.message?.content;
            if (content) {
                const chatGPTResponse = {
                    message: content,
                    sender: "ChatGPT",
                    sentTime: getCurrentDateTime(),
                };
                setMessages((prevMessages) => [...prevMessages, chatGPTResponse]);
            }
        } catch (error) {
            notifyError("Error processing message:", error);
        } finally {
            setIsTyping(false);
            setMessage('');
        }
    };

    async function processMessageToChatGPT(chatMessages) {
        const apiMessages = chatMessages.map((messageObject) => {
            const role = messageObject.sender === "ChatGPT" ? "assistant" : "user";
            return { role, content: messageObject.message };
        });

        const apiRequestBody = {
            "model": "gpt-3.5-turbo",
            "messages": [
                { role: "assistant", content: contextBot },
                ...apiMessages,
            ],
            "temperature": 0.2
        };

        const response = await fetch("https://api.openai.com/v1/chat/completions", {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + OPENAI_KEY,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(apiRequestBody),
        });

        return response.json();
    }

    return (
        <Modal open={isOpen} onClose={handleCloseModal} size="small" style={{width: '75%'}}>
            <Header icon="comments" content="Suporte ao Cliente" className="primary-color" />
            <Modal.Content scrolling>
                {onLoad &&
                    <Segment basic textAlign='center'>
                        <Loader inline active size='medium' className="step-title">Carregando...</Loader>
                    </Segment>
                }
                {!onLoad &&
                    <>
                        {messages.map((message, i) => (
                            <div key={i} style={{ textAlign: message.sender === 'user' ? 'right' : 'left', marginBottom: '10px' }}>
                                <div style={{ display: 'inline-block', padding: '5px 10px', borderRadius: '15px', backgroundColor: message.sender === 'user' ? '#f0f0f0' : '#d4edda', maxWidth: '80%', color: "black" }}>
                                    <ReactMarkdown>{message.message}</ReactMarkdown>
                                    <small>{message.sentTime}</small>
                                </div>
                            </div>
                        ))}
                        {isTyping && (
                            <Segment basic textAlign='center'>
                                <Loader inline active size='medium' className="step-title">Escrevendo...</Loader>
                            </Segment>
                        )}
                    </>
                }
                <Form onSubmit={handleSubmit}>
                    <Form.TextArea
                        value={messageToSend}
                        onChange={handleChange}
                        placeholder="Escreva a sua mensagem..."
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions className="tertiary-color">
                <Button primary onClick={handleSubmit}>
                    <Icon name="send" /> Enviar
                </Button>
                <Button negative onClick={handleCloseModal}>
                    Fechar
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default SupportChatModal;