import React from 'react';
import { Container } from 'semantic-ui-react';
import FAQHeader from './components/FAQHeader';
import FAQItem from './components/FAQItem';
import FAQCTA from './components/FAQCTA';
import AppearOnScroll from '../../global/AppearOnScroll';

const faqs = [
  {
    question: "Como posso criar uma conta na myPET.ID?",
    answer: "Para criar uma conta, clique no botão 'Registar' na barra de navegação e preencha o formulário de registo com informações válidas."
  },
  {
    question: "Como adiciono um animal de estimação à minha conta?",
    answer: "Após entrar na sua conta, na página inicial, clique em '+ Adicionar um pet'. Selecione o tipo de animal (cão, gato, ave, roedor ou réptil) e preencha as informações sobre o pet."
  },
  {
    question: "Como posso editar as informações do meu pet?",
    answer: "Na página inicial, onde a lista de pets é exibida, clique no pet que deseja editar. Clique no ícone do meio (um quadrado com um lápis) para editar as informações do pet."
  },
  {
    question: "Como adiciono informações sobre a saúde do meu pet?",
    answer: "Primeiro, adicione um pet à sua conta. Em seguida, na página de edição do pet, você poderá adicionar informações detalhadas sobre saúde, incluindo veterinários, vacinação, desparasitação, medicação, cirurgias, pesagens e, para cães e gatos, também o chip. Navegue até o separador desejado e clique em '+ Adicionar...' para incluir novas informações."
  },
  {
    question: "É necessário ter um veterinário adicionado para registar vacinação, medicação e cirurgias?",
    answer: "Sim, para adicionar informações sobre vacinação, medicação e cirurgias, é necessário primeiro adicionar um veterinário à sua conta."
  },
  {
    question: "Como posso visualizar um resumo detalhado das informações do meu pet?",
    answer: "Na página inicial, clique no pet cujas informações deseja visualizar detalhadamente. Em seguida, clique no ícone de olho aberto para aceder ao resumo detalhado."
  },
  {
    question: "Como partilho informações do meu pet com outras pessoas?",
    answer: "Na vista detalhada das informações do pet, clique no botão 'Partilhar' para partilhar as informações com alguém."
  },
  {
    question: "Como apago informações de saúde do meu pet?",
    answer: "Para apagar informações específicas sobre a saúde do seu pet, como registos de vacinação ou medicação, navegue até a informação que deseja remover e clique no botão 'Apagar' ao lado do item."
  },
  {
    question: "Como posso apagar um pet da minha conta?",
    answer: "Na página inicial, clique no pet que deseja apagar. Depois, clique no ícone 'X' para remover o pet da sua conta."
  }
];

const FAQPage = () => (
  <Container fluid>
    <FAQHeader />
    <br />
    <Container>
      {faqs.map((faq, index) => (
        <AppearOnScroll key={index} delay={index}>
          <FAQItem question={faq.question} answer={faq.answer} />
        </AppearOnScroll>
      ))}
      <FAQCTA />
    </Container>
    <br />
  </Container>
);

export default FAQPage;
