import React from 'react';
import CompanyHistory from './components/CompanyHistory';
import MissionAndVision from './components/MissionAndVision';
import Differentiators from './components/Differentiators';
import SocialCommitment from './components/SocialCommitment';
import FadeInOnScroll from '../../global/FadeInOnScroll';

const AboutUs = () => (
  <div style={{ minHeight: '70.5vh' }}>
    <FadeInOnScroll><CompanyHistory /></FadeInOnScroll>
    <FadeInOnScroll><MissionAndVision /></FadeInOnScroll>
    <FadeInOnScroll><Differentiators /></FadeInOnScroll>
    <FadeInOnScroll><SocialCommitment /></FadeInOnScroll>
  </div>
);

export default AboutUs;
