import React from 'react';
import { Header, Segment, Icon, Divider, Grid, Image, Container, Step } from 'semantic-ui-react';
import photo from '../../../../resources/other/rescueCommitment.png'

const SocialCommitment = () => {
  return (
    <Segment inverted style={{ padding: '2em 0em' }} vertical textAlign='center'>
      <Header as='h3' style={{ fontSize: '2em', textAlign: 'center' }}>
        Compromisso Social
      </Header>
      <Divider style={{ maxWidth: '80%', margin: 'auto' }} />
      <br />
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={8}>
            <Image circular centered size='large' src={photo} alt="História do myPET.ID" style={{ border: '5px solid #51cb61', padding: '4px', backgroundColor: '#fff' }} />
          </Grid.Column>
          <Grid.Column width={8}>
            <p style={{ fontSize: '1.33em', textAlign: 'center' }}>
              Atualmente, estamos a explorar formas de como o <strong className='step-title'>myPET.ID</strong> pode contribuir positivamente para a comunidade de animais de estimação e além, ainda estejamos nos estágios iniciais, estamos empenhados em encontrar maneiras de integrar a responsabilidade social na nossa missão.
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Container>
              <Step.Group vertical>
                <Step style={{ backgroundColor: 'transparent' }}>
                  <Icon name='heart' color='red' />
                  <Step.Content>
                    <Step.Title className='step-title'>Parcerias com Abrigos de Animais</Step.Title>
                    <Step.Description className='step-content'>Apoio a programas de adoção, ajudando a encontrar lares para animais sem família.</Step.Description>
                  </Step.Content>
                </Step>

                <Step style={{ backgroundColor: 'transparent' }}>
                  <Icon name='tree' color='green' />
                  <Step.Content>
                    <Step.Title className='step-title'>Iniciativas de Sustentabilidade Ambiental</Step.Title>
                    <Step.Description className='step-content'>Promovendo práticas que protegem o nosso planeta para as futuras gerações de pets e seus donos.</Step.Description>
                  </Step.Content>
                </Step>
              </Step.Group>
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default SocialCommitment;
