import React from 'react';
import { Icon, Image, Modal } from 'semantic-ui-react';
import { calculatePetAge } from '../../../../resources/lib';
import photo from '../../../../resources/avatars/bird.png'

const BirdProfilePreview = ({ pet, petPhoto, measurements }) => {
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Image wrapped size="medium" src={petPhoto == null ? photo : petPhoto} alt="Foto do Pet" circular style={{ marginTop: '10px' }} />
            </div>
            <Modal.Description style={{ textAlign: 'center' }}>
                <p style={{ margin: '0px' }}><Icon name={pet.sex === 'male' ? 'man' : 'woman'} /> {pet.species}</p>
                <p style={{ margin: '0px' }}>{calculatePetAge(pet.birthDate)}</p>
                <p style={{ margin: '0px' }}>{pet.featherColor}</p>
                {measurements.length > 0 &&
                    <p style={{ margin: '0px' }}>{measurements[0].weight} kg</p>
                }
            </Modal.Description>
        </>
    );
};

export default BirdProfilePreview;