import React, { useState } from 'react';
import { Form, Button, Select, Input } from 'semantic-ui-react';
import { LogErrors, getCurrentDateTime, notifyError, notifySuccess, removeId } from '../../../resources/lib';
import { surgeriesCollection } from '../../../resources/const/firebaseCollections';
import { addDataToCollection, updateDataByDocId } from '../../../resources/firebaseLib';

const SurgeryForm = ({ pet, reload, veterinarians, surgery, closeModal }) => {
  const [formData, setFormData] = useState(surgery ? surgery : {
    surgeryName: '',
    surgeryDate: '',
    restPeriod: '',
    checkupDate: '',
    surgeonName: ''
  });
  const isUpdate = surgery ? true : false;

  const formatVeterinarians = (veterinarians) => {
    let vetOptions = [];
    let counter = 0;
    veterinarians.forEach(vet => {
      vetOptions.push({
        key: counter,
        text: vet.name,
        value: vet.id
      });
      counter++;
    });
    return vetOptions;
  }

  const handleChange = (e, { name, value }) => setFormData({ ...formData, [name]: value });

  async function populateCollection(data, uid) {
    try {
      const addData = {
        uid: uid,
        surgeryName: data.surgeryName.trim(),
        surgeryDate: data.surgeryDate,
        restPeriod: data.restPeriod.trim(),
        checkupDate: data.checkupDate,
        surgeonName: data.surgeonName,
        creationDate: getCurrentDateTime()
      };

      return await addDataToCollection(surgeriesCollection, addData);
    } catch (error) {
      LogErrors(error);
      return false;
    }
  };

  const validObject = () => {
    return (formData.surgeryName.trim() !== '' &&
      formData.surgeryDate !== '' &&
      formData.restPeriod !== '' &&
      formData.checkupDate !== '' &&
      formData.surgeonName !== '');
  }

  const handleSubmit = async () => {
    if (!isUpdate) {
      const result = await populateCollection(formData, pet.id);
      if (result) {
        notifySuccess('Cirurgia adicionada com sucesso.');
        reload(pet);
      }
      else {
        notifyError('Falha ao criar a sua Cirurgia.');
      }
    }
    else {
      const result = await updateDataByDocId(surgeriesCollection, formData.id, removeId(formData));
      if (result) {
        notifySuccess('Cirurgia atualizado com sucesso.');
        reload(pet);
        closeModal();
      }
      else {
        notifyError('Falha ao atualizar o seu Cirurgia.');
        closeModal();
      }
    }
    closeModal(true);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Field required>
        <label>Nome da Cirurgia</label>
        <Input
          placeholder='Castração, Limpeza Dentária, ...'
          name='surgeryName'
          value={formData.surgeryName}
          onChange={handleChange}
        />
      </Form.Field>
      <Form.Field required>
        <label>Data da Cirurgia</label>
        <Input
          type='date'
          name='surgeryDate'
          value={formData.surgeryDate}
          onChange={handleChange}
        />
      </Form.Field>
      <Form.Field required>
        <label>Período de Descanso</label>
        <Input
          placeholder='2 semanas'
          name='restPeriod'
          value={formData.restPeriod}
          onChange={handleChange}
        />
      </Form.Field>
      <Form.Field required>
        <label>Data para Consulta de Revisão</label>
        <Input
          type='date'
          name='checkupDate'
          value={formData.checkupDate}
          onChange={handleChange}
        />
      </Form.Field>
      {veterinarians.length > 0 &&
        <Form.Input
          control={Select}
          label='Nome do Cirurgião'
          options={formatVeterinarians(veterinarians)}
          placeholder='Selecione o cirurgião'
          name='surgeonName'
          value={formData.surgeonName}
          onChange={handleChange}
          required
        />
      }
      {veterinarians.length === 0 &&
        <Form.Field>
          <label>Adiciona um Veterinário primeiro.</label>
        </Form.Field>
      }
      <Button type='submit' className='primary-color' disabled={!validObject()}>Gravar</Button>
    </Form>
  );
};

export default SurgeryForm;
