import React from 'react';
import { Container, Segment, Header, Divider, Step, Icon } from 'semantic-ui-react';

const DifferentiatorsSteps = () => {
  return (
    <Segment inverted style={{ padding: '2em 0em' }} vertical>
      <Header as='h3' style={{ fontSize: '2em', textAlign: 'center' }}>
        O Que Nos Distingue
      </Header>
      <Divider style={{ maxWidth: '80%', margin: 'auto' }} />
      <br />
      <Container>
        <Step.Group fluid vertical>
          <Step style={{backgroundColor: 'transparent'}}>
            <Icon name='check circle' color='green' />
            <Step.Content>
              <Step.Title className='step-title'>Simplicidade e Acesso</Step.Title>
              <Step.Description className='step-content'>Tornar a informação sobre os pets rapidamente acessível, simplificando a vida dos donos de animais de estimação.</Step.Description>
            </Step.Content>
          </Step>

          <Step style={{backgroundColor: 'transparent'}}>
            <Icon name='check circle' color='green' />
            <Step.Content>
              <Step.Title className='step-title'>Abordagem Centrada no Utilizador</Step.Title>
              <Step.Description className='step-content'>Desenhada a partir de experiências pessoais reais com a gestão da saúde dos pets, oferecendo uma plataforma intuitiva.</Step.Description>
            </Step.Content>
          </Step>

          <Step style={{backgroundColor: 'transparent'}}>
            <Icon name='check circle' color='green' />
            <Step.Content>
              <Step.Title className='step-title'>Recurso Indispensável</Step.Title>
              <Step.Description className='step-content'>Ambicionamos ser mais do que uma aplicação; queremos ser um recurso indispensável para os donos de animais de estimação, promovendo o bem-estar dos seus companheiros.</Step.Description>
            </Step.Content>
          </Step>
        </Step.Group>
      </Container>
    </Segment>
  );
};

export default DifferentiatorsSteps;