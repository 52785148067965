import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
//import { getAnalytics } from "firebase/analytics";

//PROD
const firebaseConfig = {
    apiKey: "AIzaSyCJa0M5V5FeDz6BNycO0JEIUp9YZ_xLn1E",
    authDomain: "mypetid-1717.firebaseapp.com",
    projectId: "mypetid-1717",
    storageBucket: "mypetid-1717.appspot.com",
    messagingSenderId: "664618657414",
    appId: "1:664618657414:web:a669f65c8dae8168de1307",
    measurementId: "G-BLD46FQRRW"
};

//TEST
const firebaseConfigTest = {
    apiKey: "AIzaSyCJa0M5V5FeDz6BNycO0JEIUp9YZ_xLn1E",
    authDomain: "mypetid-1717.firebaseapp.com",
    projectId: "mypetid-1717",
    storageBucket: "mypetid-1717.appspot.com",
    messagingSenderId: "664618657414",
    appId: "1:664618657414:web:a669f65c8dae8168de1307",
    measurementId: "G-BLD46FQRRW"
};

const app = initializeApp(process.env.REACT_APP_ENV !== 'dev' ? firebaseConfig : firebaseConfigTest)
//const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)
//const analytics = getAnalytics(app);