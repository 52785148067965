import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, Header, List, Segment } from 'semantic-ui-react';
import { getCurrentDateTime } from '../../resources/lib';
import { SUPPORT_EMAIL } from '../../resources/const/constants';

const Footer = () => {
  const email = SUPPORT_EMAIL;
  const subject = encodeURIComponent(`Contactar - myPET.ID - ${getCurrentDateTime()}`);
  const body = encodeURIComponent("Escreva a sua mensagem aqui...");

  return (
    <Segment inverted vertical style={{ padding: '5em 0em' }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <Header inverted as='h4' content='Sobre' />
              <List link inverted>
                <List.Item as={Link} to='/about'>Quem Somos</List.Item>
                <List.Item as='a' href={`mailto:${email}?subject=${subject}&body=${body}`}>Contato</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header inverted as='h4' content='Serviços' />
              <List link inverted>
                <List.Item as={Link} to='/faq'>FAQ</List.Item>
                <List.Item as={Link} to='/support'>Suporte</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={7}>
              <Header as='h4' inverted>
                myPET.ID
              </Header>
              <p>
                A plataforma definitiva para conectar e cuidar melhor do seu pet.
              </p>
              <p>&copy; myPET.ID 2024</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
};

export default Footer;
