export const usersCollection ='user';
export const dogsCollection ='dog';
export const catsCollection ='cat';
export const rodentsCollection ='rodent';
export const birdsCollection ='bird';
export const reptilesCollection ='reptile';
export const vetsCollection ='vet';
export const vaccinesCollection ='vaccine';
export const dewormingsCollection ='deworming';
export const medicationsCollection ='medication';
export const surgeriesCollection ='surgery';
export const measurementsCollection ='measurement';
export const chipsCollection ='chip';
export const queuesCollection ='queue';
export const messageCounterCollection ='messageCounter';