import React, { useState } from 'react';
import { Form, Button, Dropdown } from 'semantic-ui-react';
import { LogErrors, formatCities, getCurrentDateTime, notifyError, notifySuccess, removeId } from '../../../resources/lib';
import { vetsCollection } from '../../../resources/const/firebaseCollections';
import { addDataToCollection, updateDataByDocId } from '../../../resources/firebaseLib';

const VeterinarianForm = ({ pet, reload, veterinarian, closeModal }) => {
  const [formData, setFormData] = useState(veterinarian ? veterinarian : {
    name: '',
    clinicName: '',
    city: '',
    contact: '',
    email: ''
  });
  const isUpdate = veterinarian ? true : false;

  const handleChange = (e, { name, value }) => setFormData({ ...formData, [name]: value });

  const handleDropChange = (e, { name, value }) => setFormData({ ...formData, [name]: value });

  async function populateCollection(data, uid) {
    try {
      const addData = {
        uid: uid,
        name: data.name.trim(),
        clinicName: data.clinicName.trim(),
        city: data.city.trim(),
        contact: data.contact.trim(),
        email: data.email.trim(),
        creationDate: getCurrentDateTime()
      };

      return await addDataToCollection(vetsCollection, addData);
    } catch (error) {
      LogErrors(error);
      return false;
    }
  };

  const validObject = () => {
    return (formData.name.trim() !== '' &&
      formData.clinicName.trim() !== '' &&
      formData.city !== '');
  }

  const handleSubmit = async () => {
    if (!isUpdate) {
      const result = await populateCollection(formData, pet.id);
      if (result) {
        notifySuccess('Veterinário adicionado com sucesso.');
        reload(pet);
      }
      else {
        notifyError('Falha ao criar o seu Veterinário.');
      }
    }
    else {
      const result = await updateDataByDocId(vetsCollection, formData.id, removeId(formData));
      if (result) {
        notifySuccess('Veterinário atualizado com sucesso.');
        reload(pet);
        closeModal();
      }
      else {
        notifyError('Falha ao atualizar o seu Veterinário.');
        closeModal();
      }
    }
    closeModal(true);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Input
        label='Nome do Médico'
        placeholder='Nome'
        name='name'
        value={formData.name}
        onChange={handleChange}
        required
      />
      <Form.Input
        label='Nome da Clínica'
        placeholder='Clínica'
        name='clinicName'
        value={formData.clinicName}
        onChange={handleChange}
        required
      />
      <Form.Field required>
        <label>Cidade</label>
        <Dropdown
          placeholder='Cidade'
          name='city'
          fluid
          search
          selection
          options={formatCities()}
          value={formData.city}
          onChange={handleDropChange}
        />
        </Form.Field>
        <Form.Input
          label='Contato'
          placeholder='289289289, 969696969, ...'
          name='contact'
          value={formData.contact}
          onChange={handleChange}
        />
        <Form.Input
          label='Email'
          placeholder='example@gmail.com'
          name='email'
          type='email'
          value={formData.email}
          onChange={handleChange}
        />
        <Button type='submit' className='primary-color' disabled={!validObject()}>Gravar</Button>
    </Form>
  );
};

export default VeterinarianForm;
