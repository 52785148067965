const breeds = `Abissínio[8]
Egeu
Bobtail americano
Cacho Americano[12]
Rabo anelado americano
Pêlo Curto Americano
Pêlo Americano
Gigante de Afrodite
Mau árabe
Asiático
Semi-longo Asiático
Névoa Australiana
Balinês
Bebê
Bengala
birmanês
Bombaim
Cabelo Curto Brasileiro
Cabelo Longo Britânico
Pêlo Curto Britânico
birmanês
Burmilla
Califórnia Spangled
Chantilly-Tiffany
Chartreux
Chausie
Cabelo Curto Colorpoint
Rex da Cornualha
Cymric, Manx Longhair ou Manx de cabelos compridos
Chipre
Devon Rex
Donskoy ou Don Sphynx
Dragão Li ou Chinês Li Hua
Morar
Mau egípcio
Pêlo Curto Europeu
Pêlo Curto Exótico
Foldex[16]
Rex alemão
Havana Marrom
Highlander
Persa do Himalaia ou Colorpoint
Bobtail Japonês
Javanês ou Colorpoint Longhair
Kanani
Khao Manee
Kinkalow
Korat
Bobtail Coreano
Korn Ja ou Konja
Bobtail das Curilas ou Bobtail das Ilhas Curilas
Cordeiro
LaPerm
Lykoi
Maine-coon
Manx
Bobtail do Mekong
Minskin
Minueto
Munchkin
Nebelung
Máscara de Neva
Gato da floresta norueguês
Ocicat
Bicolor Oriental
Cabelo Longo Oriental
Pêlo Curto Oriental
persa
Peterbaldo
Pixie-bob
Maltrapilho ou Liebling
Boneca de pano
Raas
Azul Russo
Russo Branco, Russo Preto e Russo Tabby
Sam Sawet
Savana
Dobra Escocesa
Selkirk Rex
Serengeti
Siamês
Gato da Floresta Siberiana ou Siberiana
Singapura
Raquete de neve
Sokoke
somali
Esfinge
Sufalak
Tailandês ou Tradicional, Clássico, Wichien Maar
Lilás Tailandês, Ponto Azul Tailandês e Ponto Lilás Tailandês
Tonquinês
Bob de Brinquedo
Brinquedo
Angorá Turco
Van turca
Vankedisi Turco
Levkoy ucraniano
Chocolate York
Sem Raça Definida`;

export var catBreedList = breeds.split('\n').map(function(line) {
    return line.replace(/\[\d+\]/g, '').trim();
});