import React, { useEffect, useState } from 'react';
import { Button, Header, Icon, List, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import CreationModals from './modals/CreationModals';
import VaccineForm from './forms/VaccineForm';
import { findNameById, findObjById, getWarningColor, notifyError, notifySuccess } from '../../resources/lib';
import { deleteDocById } from '../../resources/firebaseLib';
import ConfimationModal from '../modals/ConfimationModal';
import { vaccinesCollection } from '../../resources/const/firebaseCollections';
import VeterinarianInfoModal from './views/VeterinarianInfoModal';

const VaccinationList = ({ pet, vaccines, veterinarians, isView, reload }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleModal = async (value, item) => {
        if (value) {
            const result = deleteDocById(vaccinesCollection, item.id);
            if (result) {
                notifySuccess(`Vacina apagada com sucesso.`);
            }
            else {
                notifyError(`Falha ao apagar Vacina.`);
            }
            reload(pet);
        }
    };

    return (
        <>
            <Header as="h5">Lista de Vacinas</Header>
            {vaccines.length === 0 ? (
                <Segment>Nenhuma vacina adicionada.</Segment>
            ) : (
                <List divided relaxed style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    {vaccines.map((vaccine, index) => (
                        <List.Item key={index}>
                            {windowWidth > 758 &&
                                <List.Icon name='syringe' size='large' verticalAlign='middle' />
                            }
                            <List.Content>
                                {!isView ? (
                                    <CreationModals
                                        title='Atualizar Vacina'
                                        trigger={<List.Header as={Link}>{vaccine.name}</List.Header>}
                                    >
                                        <VaccineForm pet={pet} veterinarians={veterinarians} reload={reload} vaccine={vaccine} />
                                    </CreationModals>
                                ) : (
                                    <List.Header as={Link}>{vaccine.name}</List.Header>
                                )
                                }
                                {!isView && windowWidth > 758 &&
                                    <List.Content floated='right'>
                                        <ConfimationModal
                                            title='Tem certeza de que deseja apagar esta vacina?'
                                            trigger={<Button inverted color='red'><Icon name='trash' />Apagar</Button>}
                                            action={handleModal}
                                            item={vaccine} />
                                    </List.Content>
                                }
                                <List.Description>
                                    {vaccine.purpose.replaceAll('Other', (vaccine.otherPurpose ? `Outro(${vaccine.otherPurpose})` : 'Outro'))}
                                </List.Description>
                                <List.Description>
                                    Administração: {vaccine.givenDate}
                                </List.Description>
                                <List.Description style={getWarningColor(vaccine.nextDate)}>
                                    Próxima: {vaccine.nextDate}
                                </List.Description>
                                <VeterinarianInfoModal
                                    veterinarian={findObjById(veterinarians, vaccine.doctorName)}
                                    trigger={<List.Header as={Link}>{findNameById(veterinarians, vaccine.doctorName)}</List.Header>}
                                />
                                {!isView && windowWidth <= 758 &&
                                    <List.Description>
                                        <ConfimationModal
                                            title='Tem certeza de que deseja apagar esta vacina?'
                                            trigger={<Button inverted color='red'><Icon name='trash' />Apagar</Button>}
                                            action={handleModal}
                                            item={vaccine} />
                                    </List.Description>
                                }
                            </List.Content>
                        </List.Item>
                    ))}
                </List>
            )}
            {!isView &&
                <CreationModals
                    title='Adicionar Vacina'
                    trigger={<Button
                        icon='add'
                        content='Adicionar Vacina'
                        className='primary-color'
                    />}
                >
                    <VaccineForm pet={pet} veterinarians={veterinarians} reload={reload} />
                </CreationModals>
            }
        </>
    );
};

export default VaccinationList;