import React, { useState } from 'react';
import { Modal, Button } from 'semantic-ui-react';

const ConfimationModal = ({ title, trigger: Trigger, action, item }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleModal = (e, value) => {
    e.preventDefault();
    handleClose();
    if (action)
      item ? action(value, item) : action(value);
  };

  return (
    <Modal
      trigger={<div onClick={handleOpen}>{Trigger}</div>}
      open={open}
      onClose={handleClose}
      size='small'
    >
      <Modal.Header className='primary-color'>Confirmar Exclusão</Modal.Header>
      <Modal.Content>
        <p>{title ? title : 'Tem certeza de que deseja apagar este pet?'}</p>
      </Modal.Content>
      <Modal.Actions className='tertiary-color'>
        <Button negative onClick={e => handleModal(e, false)}>Não</Button>
        <Button positive onClick={e => handleModal(e, true)}>Sim</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ConfimationModal;

