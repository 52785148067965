import React, { useEffect, useState } from 'react';
import { Button, Header, Icon, List, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import CreationModals from './modals/CreationModals';
import SurgeryForm from './forms/SurgeryForm';
import { findNameById, findObjById, notifyError, notifySuccess } from '../../resources/lib';
import { surgeriesCollection } from '../../resources/const/firebaseCollections';
import { deleteDocById } from '../../resources/firebaseLib';
import ConfimationModal from '../modals/ConfimationModal';
import VeterinarianInfoModal from './views/VeterinarianInfoModal';

const SurgeryList = ({ pet, surgeries, veterinarians, isView, reload }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleModal = async (value, item) => {
        if (value) {
            const result = deleteDocById(surgeriesCollection, item.id);
            if (result) {
                notifySuccess(`Cirurgia apagada com sucesso.`);
            }
            else {
                notifyError(`Falha ao apagar Cirurgia.`);
            }
            reload(pet);
        }
    };

    return (
        <>
            <Header as="h5">Lista de Cirurgias</Header>
            {surgeries.length === 0 ? (
                <Segment>Nenhuma cirurgia adicionada.</Segment>
            ) : (
                <List divided relaxed style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    {surgeries.map((surgery, index) => (
                        <List.Item key={index}>
                            {windowWidth > 758 &&
                                <List.Icon name='hospital' size='large' verticalAlign='middle' />
                            }
                            <List.Content>
                                {!isView ? (
                                    <CreationModals
                                        title='Atualizar Cirurgia'
                                        trigger={<List.Header as={Link}>{surgery.surgeryName}</List.Header>}
                                    >
                                        <SurgeryForm pet={pet} reload={reload} veterinarians={veterinarians} surgery={surgery} />
                                    </CreationModals>
                                ) : (
                                    <List.Header as={Link}>{surgery.surgeryName}</List.Header>
                                )
                                }
                                <List.Description>Realização: {surgery.surgeryDate}</List.Description>
                                {!isView && windowWidth > 758 &&
                                    <List.Content floated='right'>
                                        <ConfimationModal
                                            title='Tem certeza de que deseja apagar esta cirurgia?'
                                            trigger={<Button inverted color='red'><Icon name='trash' />Apagar</Button>}
                                            action={handleModal}
                                            item={surgery} />
                                    </List.Content>
                                }
                                <List.Description>Descanso: {surgery.restPeriod}</List.Description>
                                <List.Description>Revisão: {surgery.checkupDate}</List.Description>
                                <List.Header>{}</List.Header>
                                <VeterinarianInfoModal
                                    veterinarian={findObjById(veterinarians, surgery.surgeonName)}
                                    trigger={<List.Header as={Link}>{findNameById(veterinarians, surgery.surgeonName)}</List.Header>}
                                />
                                {!isView && windowWidth <= 758 &&
                                    <List.Description>
                                        <ConfimationModal
                                            title='Tem certeza de que deseja apagar esta cirurgia?'
                                            trigger={<Button inverted color='red'><Icon name='trash' />Apagar</Button>}
                                            action={handleModal}
                                            item={surgery} />
                                    </List.Description>
                                }
                            </List.Content>
                        </List.Item>
                    ))}
                </List >
            )}
            {!isView &&
                <CreationModals
                    title='Adicionar Cirurgia'
                    trigger={<Button
                        icon='add'
                        content='Adicionar Cirurgia'
                        className='primary-color'
                    />}
                >
                    <SurgeryForm pet={pet} reload={reload} veterinarians={veterinarians} />
                </CreationModals>
            }
        </>
    );
};

export default SurgeryList;