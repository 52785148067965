import { auth, db } from '../firebase';
import { deleteUser } from 'firebase/auth';
import { collection, query, where, doc, getDoc, getDocs, addDoc, updateDoc, deleteDoc, limit, orderBy } from 'firebase/firestore';
import { LogErrors, getCurrentDate, getCurrentDateTime, removeId } from './lib';

const pageSize = 50

export const collectionNames = {
    booking: 'booking',
    business: 'business',
    business_services: 'business_services',
    business_socials: 'business_socials',
    business_work_times: 'business_work_times',
    category: 'category',
    client_account: 'client_user',
    business_account: 'client_business',
    payment_methods: 'payment_methods',
    socials: 'socials',
    emails: 'emails',
    feedback_token: 'feedback_token'
}

export function isLogged(auth) {
    return (auth?.currentUser !== null && auth?.currentUser !== undefined)
}

export function getDocRef(collectionName, doc) {
    try {
        return doc(db, collectionName, doc.id)
    } catch (error) {
        LogErrors(error)
        return null
    }
}

export async function getDocById(collectionName, documentId) {
    try {
        const docRef = doc(db, collectionName, documentId)
        const docSnapshot = await getDoc(docRef)

        if (docSnapshot.exists()) {
            return { id: docSnapshot.id, ...docSnapshot.data() }
        } else {
            return null
        }
    } catch (error) {
        LogErrors(error)
        return null
    }
}

export async function getAllDocuments(collectionName) {
    try {
        let q
        q = query(
            collection(db, collectionName)
        )

        const querySnapshot = await getDocs(q)
        const documents = []

        querySnapshot.forEach((doc) => {
            documents.push({ id: doc.id, ...doc.data() })
        })

        return documents
    } catch (error) {
        LogErrors(error)
        return []
    }
}

export async function getDocIdBy(collectionName, search, uid) {
    try {
        const q = query(collection(db, collectionName), where(search, '==', uid))

        const querySnapshot = await getDocs(q)

        if (querySnapshot.size === 0) {
            return null
        }

        return querySnapshot.docs[0].id
    } catch (error) {
        LogErrors(error)
        return null
    }
}

export async function getDataBy(collectionName, search, uid) {
    try {
        const q = query(collection(db, collectionName), where(search, '==', uid))

        const querySnapshot = await getDocs(q)

        let dataList = []
        querySnapshot.forEach((doc) => {
            dataList.push({ id: doc.id, ...doc.data() })
        })
        return dataList
    } catch (error) {
        LogErrors(error)
        return []
    }
}

export async function getDataByLazy(collectionName, search, uid, finishNumber = 1) {
    try {
        const q = query(
            collection(db, collectionName),
            where(search, '==', uid),
            limit(pageSize * finishNumber)
        )

        const querySnapshot = await getDocs(q)

        let dataList = []
        querySnapshot.forEach((doc) => {
            dataList.push({ id: doc.id, ...doc.data() })
        })
        finishNumber += 1
        return { dataList, finishNumber }
    } catch (error) {
        LogErrors("Error fetching data: ", error)
        return { dataList: [], finishNumber: 1 }
    }
}

export async function getDataByOrdeyBy2Props(collectionName, search, uid, ordeyByProp1, ordeyByProp2) {
    try {
        const q = query(
            collection(db, collectionName),
            where(search, '==', uid),
            orderBy(ordeyByProp1, "desc"),
            orderBy(ordeyByProp2, "desc"))

        const querySnapshot = await getDocs(q)

        let dataList = []
        querySnapshot.forEach((doc) => {
            dataList.push({ id: doc.id, ...doc.data() })
        })
        return dataList
    } catch (error) {
        LogErrors(error)
        return []
    }
}

export async function getDataByOrdeyBy2PropsLazyLoading(collectionName, search, uid, ordeyByProp1, ordeyByProp2, finishNumber = 1) {
    try {
        let q = query(
            collection(db, collectionName),
            where(search, '==', uid),
            orderBy(ordeyByProp1, "desc"),
            orderBy(ordeyByProp2, "desc"),
            limit(pageSize * finishNumber)
        )

        const querySnapshot = await getDocs(q)

        let dataList = []
        querySnapshot.forEach((doc) => {
            dataList.push({ id: doc.id, ...doc.data() })
        })
        finishNumber += 1
        return { dataList, finishNumber }
    } catch (error) {
        LogErrors("Error fetching data: ", error)
        return { dataList: [], finishNumber: 1 }
    }
}

export async function getDataBy2Wheres(collectionName, search1, search2, value1, value2, finishNumber = 1) {
    try {
        let q = query(
            collection(db, collectionName),
            where(search1, '==', value1),
            where(search2, '==', value2),
            limit(pageSize * finishNumber)
        )

        const querySnapshot = await getDocs(q)

        let dataList = []
        querySnapshot.forEach((doc) => {
            dataList.push({ id: doc.id, ...doc.data() })
        })
        return dataList
    } catch (error) {
        LogErrors(error)
        return []
    }
}

export async function getDataBy2WheresGreater(collectionName, search1, search2, value1, value2) {
    try {
        const q = query(
            collection(db, collectionName),
            where(search1, '==', value1),
            where(search2, '>=', value2)
        )

        const querySnapshot = await getDocs(q)

        let dataList = []
        querySnapshot.forEach((doc) => {
            const data = doc.data()
            dataList.push({ id: doc.id, ...data })
        })
        return dataList
    } catch (error) {
        LogErrors(error)
        return []
    }
}

export async function getDataBy2WheresGreaterLazy(collectionName, search1, search2, value1, value2, finishNumber = 1) {
    try {
        const q = query(
            collection(db, collectionName),
            where(search1, '==', value1),
            where(search2, '>=', value2)
        )

        const querySnapshot = await getDocs(q)

        let dataList = []
        querySnapshot.forEach((doc) => {
            const data = doc.data()
            dataList.push({ id: doc.id, ...data })
        })
        finishNumber += 1
        return { dataList, finishNumber }
    } catch (error) {
        LogErrors("Error fetching data: ", error)
        return { dataList: [], finishNumber: 1 }
    }
}

export async function getDataBy3Wheres(collectionName, search1, search2, search3, value1, value2, value3) {
    try {
        const q = query(
            collection(db, collectionName),
            where(search1, '==', value1),
            where(search2, '==', value2),
            where(search3, '==', value3)
        )

        const querySnapshot = await getDocs(q)

        const dataList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        return dataList
    } catch (error) {
        LogErrors(error)
        return []
    }
}

export async function addDataToCollection(collectionName, newData) {
    try {
        const collectionRef = collection(db, collectionName)

        await addDoc(collectionRef, newData)
        return true
    } catch (error) {
        LogErrors(error)
        return false
    }
}

export async function updateDataByDocId(collectionName, docId, updatedData) {
    try {
        await updateDoc(doc(db, collectionName, docId), updatedData)

        return true
    } catch (error) {
        LogErrors(error)
        return false
    }
}

export async function deleteDocById(collectionName, idToDelete) {
    try {
        const docRef = doc(db, collectionName, idToDelete)
        await deleteDoc(docRef)

        return true
    } catch (error) {
        LogErrors(error)
        return false
    }
}

export async function deleteDataBy(collectionName, search, uidToDelete) {
    try {
        const q = query(collection(db, collectionName), where(search, '==', uidToDelete))

        const querySnapshot = await getDocs(q)

        querySnapshot.forEach(async (docSnapshot) => {
            const docRef = doc(db, collectionName, docSnapshot.id)
            await deleteDoc(docRef)
        })

        return true
    } catch (error) {
        LogErrors(error)
        return false
    }
}



/////////////
export async function getAllBookingsBusiness(businessId, lastPag = 1) {
    try {
        const data = await getDataByOrdeyBy2PropsLazyLoading(collectionNames.booking, 'businessId', businessId, 'date', 'time', lastPag)
        return data
    } catch (error) {
        LogErrors(error)
        return { dataList: [], finishNumber: 1 }
    }
}

export async function getAllBookingsFromTodayBusiness(businessId, lastPag = 1) {
    try {
        const data = await getDataBy2WheresGreaterLazy(collectionNames.booking, 'businessId', 'date', businessId, getCurrentDate(), lastPag)
        return data
    } catch (error) {
        LogErrors(error)
        return { dataList: [], finishNumber: 1 }
    }
}

export async function getBookingsBusinessAgenda(businessId, currDate) {
    try {
        const bookings = await getDataBy3Wheres(collectionNames.booking, 'businessId', 'isActive', 'date', businessId, 1, currDate)
        return bookings
    } catch (error) {
        LogErrors(error)
        return []
    }
}

export async function getAllBookingsClient(clientId, finishNumber = 1) {
    try {
        const data = await getDataByLazy(collectionNames.booking, 'clientId', clientId, finishNumber)
        return data
    } catch (error) {
        LogErrors(error)
        return { dataList: [], finishNumber: 1 }
    }
}

export async function getAllBookingsFromTodayClient(clientId, finishNumber = 1) {
    try {
        const data = await getDataBy2WheresGreaterLazy(collectionNames.booking, 'clientId', 'date', clientId, getCurrentDate(), finishNumber)
        return data
    } catch (error) {
        LogErrors(error)
        return { dataList: [], finishNumber: 1 }
    }
}

export async function getBookingsClientAgenda(clientId, currDate) {
    try {
        const bookings = await getDataBy3Wheres(collectionNames.booking, 'clientId', 'isActive', 'date', clientId, 1, currDate)
        return bookings
    } catch (error) {
        LogErrors(error)
        return []
    }
}

export async function deleteAuthAccount() {
    try {
        await deleteUser(auth.currentUser)
        return true
    }
    catch (error) {
        LogErrors(error)
        return false
    }
}

export async function deleteAccount(uidToDelete, type) {
    try {
        let userCollection = type === 'business' ? collectionNames.business_account : collectionNames.client_account

        let clientAccount = await getDataBy(collectionNames.client_account, 'uid', uidToDelete)
        let businessAccount = await getDataBy(collectionNames.business_account, 'uid', uidToDelete)

        let account = type === 'business' ? businessAccount[0] : clientAccount[0]

        account.isActive = 0
        account.isDelete = 1
        let result = await updateDataByDocId(userCollection, account.id, removeId(account))
        if (!result)
            return result

        return true
    } catch (error) {
        LogErrors(error)
        return false
    }
}

export async function createEmail(email, title, text, bookingId = 0, actionDate = getCurrentDateTime()) {
    try {
        const addData = {
            email: email,
            title: title,
            text: text,
            isDone: 0,
            creationDate: getCurrentDateTime(),
            actionDate: actionDate,
            completeDate: getCurrentDateTime(),
            bookingId: bookingId
        }

        return await addDataToCollection(collectionNames.emails, addData)
    } catch (error) {
        LogErrors(error)
        return false
    }
}

export async function createFeedbackToken(guid, bookingEmailId) {
    try {
        const addData = {
            guid,
            bookingEmailId
        }

        return await addDataToCollection(collectionNames.feedback_token, addData)
    } catch (error) {
        LogErrors(error)
        return false
    }
}