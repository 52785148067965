import React from 'react';
import { Segment, Header, Divider, Icon } from 'semantic-ui-react';

const MissionAndVision = () => (
  <Segment basic style={{ padding: '2em 2em' }}>
    <Header as='h3' style={{ fontSize: '2em', textAlign: 'center' }}>
      Missão e Visão
    </Header>
    <Divider style={{ maxWidth: '80%', margin: 'auto' }} />
    <Segment>
      <Header as='h4'>
        <Icon name='world' />
        Missão
      </Header>
      <p style={{ fontSize: '1.33em' }}>
        No coração do <strong className='step-title'>myPET.ID</strong> está o compromisso de tornar a gestão da saúde e bem-estar dos animais de estimação tão simples e acessível quanto possível. A nossa missão é oferecer aos donos de animais de estimação uma plataforma centralizada onde possam guardar e aceder rapidamente a todas as informações essenciais sobre os seus pets. Quer se trate de vacinação, desparasitação ou qualquer outro detalhe de saúde, o <strong className='step-title'>myPET.ID</strong> elimina a necessidade de transportar documentos físicos, garantindo que os donos estejam sempre preparados, mesmo em situações de emergência.
      </p>
    </Segment>
    <Segment>
      <Header as='h4'>
        <Icon name='eye' />
        Visão
      </Header>
      <p style={{ fontSize: '1.33em' }}>
        Sonhamos com um mundo onde cada dono de animal de estimação possa concentrar-se no que realmente importa: o tempo de qualidade passado com os seus companheiros. A nossa visão é expandir e evoluir o <strong className='step-title'>myPET.ID</strong> para se tornar a solução go-to para o cuidado de animais de estimação, tornando cada aspeto do cuidado e gestão dos pets intuitivo, eficaz e livre de preocupações. Almejamos criar uma comunidade de donos de animais de estimação informados, responsáveis e felizes, apoiada por uma plataforma que valoriza a saúde e a felicidade dos seus pets acima de tudo.
      </p>
    </Segment>
  </Segment>
);

export default MissionAndVision;


