import React, { useState } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';

const FAQItem = ({ question, answer }) => {
  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(!active);
  };

  return (
    <Accordion styled fluid>
      <Accordion.Title active={active} onClick={handleClick}>
        <Icon name='dropdown' />
        {question}
      </Accordion.Title>
      <Accordion.Content active={active}>
        <p>{answer}</p>
      </Accordion.Content>
    </Accordion>
  );
};

export default FAQItem;
