export const sexOptions = [
    { key: 'male', value: 'male', text: 'Macho' },
    { key: 'female', value: 'female', text: 'Fêmea' },
];

export const dogFurTypes = [
    { key: 'short', value: 'short', text: 'Pelo Curto' },
    { key: 'long', value: 'long', text: 'Pelo Longo' },
    { key: 'smooth', value: 'smooth', text: 'Pelo Liso' },
    { key: 'wavy_curly', value: 'wavy_curly', text: 'Pelo Ondulado/Encaracolado' },
    { key: 'wire', value: 'wire', text: 'Pelo Duro/de Arame' },
    { key: 'double', value: 'double', text: 'Pelo Duplo' },
    { key: 'hairless', value: 'hairless', text: 'Sem Pelo' }
];

export const catFurTypes = [
    { key: 'short', value: 'short', text: 'Pelo Curto' },
    { key: 'long', value: 'long', text: 'Pelo Longo' },
    { key: 'silky', value: 'silky', text: 'Pelo Sedoso' },
    { key: 'wire', value: 'wire', text: 'Pelo de Arame' },
    { key: 'hairless', value: 'hairless', text: 'Sem Pelo' },
    { key: 'double', value: 'double', text: 'Pelo Duplo' },
    { key: 'curly', value: 'curly', text: 'Pelo Cacheado' }
];

export const reptileSkinPatterns = [
    { key: 'solid', value: 'solid', text: 'Sólido' },
    { key: 'striped', value: 'striped', text: 'Listrado' },
    { key: 'spotted', value: 'spotted', text: 'Manchado' },
    { key: 'banded', value: 'banded', text: 'Bandeado' },
    { key: 'mixed', value: 'mixed', text: 'Misto' },
    { key: 'other', value: 'other', text: 'Outro' },
];