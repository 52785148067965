import React, { useState } from 'react';
import { Button, Form, Input, Container, Select, Dropdown, Checkbox } from 'semantic-ui-react';
import { LogErrors, getCurrentDateTime, getDogBreeds, notifyError, notifySuccess, removeId } from '../../resources/lib';
import { addDataToCollection, updateDataByDocId } from '../../resources/firebaseLib';
import { dogsCollection } from '../../resources/const/firebaseCollections';
import { useAuth } from '../account/AuthContext';
import { dogFurTypes, sexOptions } from '../../resources/const/dropdownOptions';

const DogForm = ({ pet, reload, closeModal }) => {
  const { user } = useAuth();
  const [petProfile, setPetProfile] = useState(pet ? pet : {
    type: 'dog',
    name: '',
    birthDate: '',
    breed: '',
    isMixedBreed: false,
    sex: '',
    coatColor: '',
    coatType: ''
  });
  const isUpdate = pet ? true : false;

  const breedOptions = getDogBreeds();

  const handleChange = (e, { name, value }) => {
    setPetProfile({ ...petProfile, [name]: value });
  };

  const handleDropChange = (e, { name, value }) => setPetProfile({ ...petProfile, [name]: value });

  const handleCheckChange = () => {
    setPetProfile({ ...petProfile, isMixedBreed: !petProfile.isMixedBreed });
  };

  async function populateCollection(data, uid) {
    try {
      const addData = {
        uid: uid,
        type: data.type,
        name: data.name.trim(),
        birthDate: data.birthDate,
        breed: data.breed,
        isMixedBreed: data.isMixedBreed,
        sex: data.sex,
        coatColor: data.coatColor,
        coatType: data.coatType,
        creationDate: getCurrentDateTime(),
        isDelete: 0
      };

      return await addDataToCollection(dogsCollection, addData);
    } catch (error) {
      LogErrors(error);
      return false;
    }
  };

  const validObject = () => {
    return (petProfile.name.trim() !== '' &&
      petProfile.birthDate !== '' &&
      petProfile.breed !== '' &&
      petProfile.sex !== '');
  }

  const handleSubmit = async () => {
    if (!isUpdate) {
      const result = await populateCollection(petProfile, user.uid);
      if (result) {
        notifySuccess('Pet adicionado com sucesso.');
        reload(user);
        closeModal();
      }
      else {
        notifyError('Falha ao criar o seu pet.');
        closeModal();
      }
    }
    else {
      const result = await updateDataByDocId(dogsCollection, petProfile.id, removeId(petProfile));
      if (result) {
        notifySuccess('Pet atualizado com sucesso.');
        reload(user);
        closeModal();
      }
      else {
        notifyError('Falha ao atualizar o seu pet.');
        closeModal();
      }
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Form.Field required>
          <label>Nome</label>
          <Input
            placeholder='Nome do Pet'
            name='name'
            value={petProfile.name}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field required>
          <label>Data de Nascimento</label>
          <Input
            placeholder='Data de Nascimento ou Idade'
            name='birthDate'
            type='date'
            value={petProfile.birthDate}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field required>
          <label>Raça</label>
          <Dropdown
            placeholder='Raça'
            name='breed'
            fluid
            search
            selection
            options={breedOptions}
            value={petProfile.breed}
            onChange={handleDropChange}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            label="A raça é misturada?"
            checked={petProfile.isMixedBreed}
            onChange={handleCheckChange}
          />
        </Form.Field>
        <Form.Field required>
          <label>Sexo</label>
          <Select
            placeholder='Selecione o sexo'
            name='sex'
            options={sexOptions}
            value={petProfile.sex}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Pêlo</label>
          <Input
            placeholder='Côr'
            name='coatColor'
            value={petProfile.coatColor}
            onChange={handleChange}
            style={{ paddingBottom: '10px' }}
          />
          <Dropdown
            placeholder='Tipo'
            name='coatType'
            fluid
            search
            selection
            options={dogFurTypes}
            value={petProfile.coatType}
            onChange={handleDropChange}
          />
        </Form.Field>
        <Button type='submit' className='primary-color' disabled={!validObject()}>Gravar Perfil do Pet</Button>
      </Form>
    </Container>
  );
};

export default DogForm;
