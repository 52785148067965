import React, { useEffect, useState } from 'react';
import { Modal, Tab, Button, Icon, Divider } from 'semantic-ui-react';
import { LogErrors, notifyError, notifySuccess, sortObjectsByProperty } from '../../resources/lib';
import { chipsCollection, dewormingsCollection, measurementsCollection, medicationsCollection, surgeriesCollection, vaccinesCollection, vetsCollection } from '../../resources/const/firebaseCollections';
import { getDataBy } from '../../resources/firebaseLib';
import VaccinationList from '../vet/VaccinationList';
import DewormingList from '../vet/DewormingList';
import MedicationList from '../vet/MedicationList'
import SurgeryList from '../vet/SurgeryList';
import MeasurementsList from '../vet/MeasurementsList';
import ChipInfo from '../vet/views/ChipInfo';
import ResponsiveTab from '../global/ResponsiveTab'
import DogProfilePreview from '../vet/views/animals/DogProfilePreview';
import CatProfilePreview from '../vet/views/animals/CatProfilePreview';
import BirdProfilePreview from '../vet/views/animals/BirdProfilePreview';
import RodentProfilePreview from '../vet/views/animals/RodentProfilePreview';
import ReptileProfilePreview from '../vet/views/animals/ReptileProfilePreview';

const PetProfilePreviewModal = ({ pet, petPhoto, trigger: Trigger, action }) => {
    const [maxHeight, setMaxHeight] = useState('500px');
    const [open, setOpen] = useState(false);

    const [veterinarians, setVeterinarians] = useState([]);
    const [vaccines, setVaccines] = useState([]);
    const [dewormings, setDewormings] = useState([]);
    const [medications, setMedications] = useState([]);
    const [surgeries, setSurgeries] = useState([]);
    const [measurements, setMeasurements] = useState([]);
    const [chip, setChip] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getVets(pet);
                await getVaccines(pet);
                await getDewormings(pet);
                await getMedications(pet);
                await getSurgeries(pet);
                await getMeasurements(pet);
                await getChip(pet);
            } catch (error) {
                LogErrors(error);
                notifyError("Falha a carregar os dados do pet.");
            }
        };

        fetchData();
    }, [pet]);

    useEffect(() => {
        const updateMaxHeight = () => {
            const newMaxHeight = `${window.innerHeight - 200}px`;
            setMaxHeight(newMaxHeight);
        };

        window.addEventListener('resize', updateMaxHeight);
        updateMaxHeight();
        return () => window.removeEventListener('resize', updateMaxHeight);
    }, []);

    async function getVets(pet) {
        const data = await getDataBy(vetsCollection, 'uid', pet.id);
        setVeterinarians(sortObjectsByProperty(data, 'creationDate', 'desc'));
    };

    async function getVaccines(pet) {
        const data = await getDataBy(vaccinesCollection, 'uid', pet.id);
        setVaccines(sortObjectsByProperty(data, 'givenDate', 'desc'));
    };

    async function getDewormings(pet) {
        const data = await getDataBy(dewormingsCollection, 'uid', pet.id);
        setDewormings(sortObjectsByProperty(data, 'creationDate', 'desc'));
    };

    async function getMedications(pet) {
        const data = await getDataBy(medicationsCollection, 'uid', pet.id);
        setMedications(sortObjectsByProperty(data, 'creationDate', 'desc'));
    };

    async function getSurgeries(pet) {
        const data = await getDataBy(surgeriesCollection, 'uid', pet.id);
        setSurgeries(sortObjectsByProperty(data, 'creationDate', 'desc'));
    };

    async function getMeasurements(pet) {
        const data = await getDataBy(measurementsCollection, 'uid', pet.id);
        setMeasurements(sortObjectsByProperty(data, 'creationDate', 'desc'));
    };

    async function getChip(pet) {
        const data = await getDataBy(chipsCollection, 'uid', pet.id);
        if (data.length > 0)
            setChip(data[0]);
        else
            setChip(null);
    };

    const handleOpen = () => {
        setOpen(true);
        action(true);
    };
    const handleClose = () => {
        setOpen(false);
        action(false);
    };

    const handleShareClick = () => {
        const urlToShare = `${window.location.origin}/pet/share/${pet.type}/${pet.id}`;

        navigator.clipboard.writeText(urlToShare)
            .then(() => {
                notifySuccess("URL copiado para a área de transferência!");
            })
            .catch(err => {
                LogErrors(err);
                notifyError('Erro ao copiar URL para a área de transferência.');
            });
    };

    const renderProfile = (pet) => {
        switch (pet.type) {
            case 'cat': return <CatProfilePreview pet={pet} petPhoto={petPhoto} measurements={measurements} />;
            case 'bird': return <BirdProfilePreview pet={pet} petPhoto={petPhoto} measurements={measurements} />;
            case 'rodent': return <RodentProfilePreview pet={pet} petPhoto={petPhoto} measurements={measurements} />;
            case 'reptile': return <ReptileProfilePreview pet={pet} petPhoto={petPhoto} measurements={measurements} />;
            default: return <DogProfilePreview pet={pet} petPhoto={petPhoto} measurements={measurements} />;
        }
    };

    // Tab panes estrutura
    const panes = [
        { menuItem: 'Vacinação', render: () => <Tab.Pane><div><VaccinationList pet={pet} vaccines={vaccines} veterinarians={veterinarians} isView={true} reload={() => { }} /></div></Tab.Pane> },
        { menuItem: 'Desparasitação', render: () => <Tab.Pane><div><DewormingList pet={pet} dewormings={dewormings} isView={true} reload={() => { }} /></div></Tab.Pane> },
        { menuItem: 'Medicação', render: () => <Tab.Pane><div><MedicationList pet={pet} medications={medications} veterinarians={veterinarians} isView={true} reload={() => { }} /></div></Tab.Pane> },
        { menuItem: 'Cirurgias', render: () => <Tab.Pane><div><SurgeryList pet={pet} surgeries={surgeries} veterinarians={veterinarians} isView={true} reload={() => { }} /></div></Tab.Pane> },
        { menuItem: 'Pesagens', render: () => <Tab.Pane><div><MeasurementsList pet={pet} measurements={measurements} isView={true} reload={() => { }} /></div></Tab.Pane> },
        pet.type === 'dog' || pet.type === 'cat' ? { menuItem: 'Chip', render: () => <Tab.Pane><div><ChipInfo chipInfo={chip} /></div></Tab.Pane> } : <></>
    ];

    return (
        <Modal
            trigger={<div onClick={handleOpen}>{Trigger}</div>}
            open={open}
            onClose={handleClose}
            size='small'
        >
            <Modal.Header className='primary-color'>{pet.name}</Modal.Header>
            <div style={{ maxHeight: maxHeight, overflowY: 'auto' }}>
                <Modal.Content image scrolling>
                    {renderProfile(pet)}
                </Modal.Content>
                <Divider />
                <ResponsiveTab panes={panes} />
            </div>
            <Modal.Actions className='tertiary-color'>
                <Button primary onClick={handleShareClick}>
                    <Icon name="share alternate" /> Partilhar
                </Button>
                <Button onClick={handleClose} negative>
                    Fechar
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default PetProfilePreviewModal;
