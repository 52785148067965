import React, { useState } from 'react';
import { Container, Header, Segment, List, Form, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { getCurrentDateTime } from '../../../resources/lib';
import AppearOnScroll from '../../global/AppearOnScroll'
import { SUPPORT_EMAIL } from '../../../resources/const/constants';
import logo from '../../../resources/new_logo_nbg.png'

const SupportPage = () => {
    const isMobile = window.innerWidth < 758;
    const email = SUPPORT_EMAIL;
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        window.location = `mailto:${email}?subject=${encodeURIComponent(`Suporte - myPET.ID - ${subject} - ${getCurrentDateTime()}`)}&body=${encodeURIComponent(message)}`;
    };

    const validObject = () => {
        return subject.trim() !== '' && message.trim() !== ''
    }

    return (
        <Container fluid>
            <Segment inverted textAlign="center" style={{ minHeight: '30vh', padding: '1em 0em' }} vertical>
                <Container
                style={{
                    backgroundImage: `url(${logo})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    textAlign: 'center',
                    padding: isMobile ? '10px 2px' : '100px 20px', 
                  }}>
                    <Header
                        as='h1'
                        inverted
                        style={{ fontSize: '4em', fontWeight: 'normal', marginBottom: 0, marginTop: '3em' }}
                    >Suporte</Header>
                    <Header
                        as='h2'
                        inverted
                        style={{ fontSize: '1.7em', fontWeight: 'normal' }}
                    >Descubra como obter ajuda utilizando os recursos de suporte do <strong className='step-title'>myPET.ID</strong>.</Header>
                </Container>
            </Segment>
            <br />

            <Container>
                <AppearOnScroll delay={1}>
                    <Segment>
                        <Header as='h3'>Canais de Suporte Disponíveis</Header>
                        <List>
                            <List.Item><strong>Email:</strong> {email} (Horários de atendimento: 9h-18h)</List.Item>
                            <List.Item><strong>Chat ao Vivo:</strong> Disponível no nosso website (apenas para utilizadores)</List.Item>
                        </List>
                    </Segment>
                </AppearOnScroll>
                <br />

                <AppearOnScroll delay={2}>
                    <Segment>
                        <p>Para respostas às perguntas mais frequentes, visite a nossa <Link to="/faq">página de Perguntas Frequentes</Link>.</p>
                    </Segment>
                </AppearOnScroll>
                <br />

                <AppearOnScroll delay={3}>
                    <Segment>
                        <Header as='h3'>Formulário de Contacto</Header>
                        <Form onSubmit={handleSubmit}>
                            <Form.Field>
                                <label>Assunto</label>
                                <input
                                    placeholder='Assunto'
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Form.TextArea
                                    label='Mensagem'
                                    placeholder='Escreva a sua mensagem...'
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                            </Form.Field>
                            <Button className='primary-color' type='submit' disabled={!validObject()}>Enviar</Button>
                        </Form>
                    </Segment>
                </AppearOnScroll>
            </Container>
            <br />
        </Container>
    );
};

export default SupportPage;