import React, { useEffect, useState } from 'react';
import { Button, Header, Icon, List, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import CreationModals from './modals/CreationModals';
import DewormingForm from './forms/DewormingForm';
import { dewormingsCollection } from '../../resources/const/firebaseCollections';
import { deleteDocById } from '../../resources/firebaseLib';
import { getWarningColor, notifyError, notifySuccess } from '../../resources/lib';
import ConfimationModal from '../modals/ConfimationModal';

const DewormingList = ({ pet, dewormings, isView, reload }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleModal = async (value, item) => {
        if (value) {
            const result = deleteDocById(dewormingsCollection, item.id);
            if (result) {
                notifySuccess(`Desparasitação apagada com sucesso.`);
            }
            else {
                notifyError(`Falha ao apagar Desparasitação.`);
            }
            reload(pet);
        }
    };

    return (
        <>
            <Header as="h5">Lista de Desparasitações</Header>
            {dewormings.length === 0 ? (
                <Segment>Nenhuma desparasitação adicionada.</Segment>
            ) : (
                <List divided relaxed style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    {dewormings.map((deworming, index) => (
                        <List.Item key={index}>
                            {windowWidth > 758 &&
                                <List.Icon name='bug' size='large' verticalAlign='middle' />
                            }
                            <List.Content>
                                {!isView ? (
                                    <CreationModals
                                        title='Atualizar Desparasitação'
                                        trigger={<List.Header as={Link}>{deworming.type}</List.Header>}
                                    >
                                        <DewormingForm pet={pet} deworming={deworming} reload={reload} />
                                    </CreationModals>
                                ) : (
                                    <List.Header as={Link}>{deworming.type}</List.Header>
                                )
                                }
                                {!isView && windowWidth > 758 &&
                                    <List.Content floated='right'>
                                        <ConfimationModal
                                            title='Tem certeza de que deseja apagar esta desparasitação?'
                                            trigger={<Button inverted color='red'><Icon name='trash' />Apagar</Button>}
                                            action={handleModal}
                                            item={deworming} />
                                    </List.Content>
                                }
                                <List.Description>
                                    {deworming.administrationType}
                                </List.Description>
                                <List.Description>
                                    Administração: {deworming.givenDate}
                                </List.Description>
                                <List.Description style={getWarningColor(deworming.nextDate)}>
                                    Próxima: {deworming.nextDate}
                                </List.Description>
                                {!isView && windowWidth <= 758 &&
                                    <List.Description>
                                        <ConfimationModal
                                            title='Tem certeza de que deseja apagar esta desparasitação?'
                                            trigger={<Button inverted color='red'><Icon name='trash' />Apagar</Button>}
                                            action={handleModal}
                                            item={deworming} />
                                    </List.Description>
                                }
                            </List.Content>
                        </List.Item>
                    ))}
                </List>
            )}
            {!isView &&
                <CreationModals
                    title='Adicionar Desparasitação'
                    trigger={<Button
                        icon='add'
                        content='Adicionar Desparasitação'
                        className='primary-color'
                    />}
                >
                    <DewormingForm pet={pet} reload={reload} />
                </CreationModals>
            }
        </>
    );
};

export default DewormingList;