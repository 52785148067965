import React, { useState } from 'react';
import { Form, Button, Select } from 'semantic-ui-react';
import { LogErrors, getCurrentDateTime, notifyError, notifySuccess, removeId } from '../../../resources/lib';
import { dewormingsCollection } from '../../../resources/const/firebaseCollections';
import { addDataToCollection, updateDataByDocId } from '../../../resources/firebaseLib';

const DewormingForm = ({ pet, reload, deworming, closeModal }) => {
  const [formData, setFormData] = useState(deworming ? deworming : {
    type: '',
    administrationType: '',
    givenDate: '',
    nextDate: ''
  });
  const isUpdate = deworming ? true : false;

  const typeOptions = [
    { key: 'interna', text: 'Interna', value: 'Interna' },
    { key: 'externa', text: 'Externa', value: 'Externa' }
  ];

  const administrationTypeOptions = [
    { key: 'ampoule', text: 'Ampôla', value: 'Ampôla' },
    { key: 'comprimido', text: 'Comprimido', value: 'Comprimido' },
    { key: 'coleira', text: 'Coleira', value: 'Coleira' },
    { key: 'gotas', text: 'Gotas', value: 'Gotas' },
    { key: 'spray', text: 'Spray', value: 'Spray' }
  ];

  const handleChange = (e, { name, value }) => setFormData({ ...formData, [name]: value });

  async function populateCollection(data, uid) {
    try {
      const addData = {
        uid: uid,
        type: data.type,
        administrationType: data.administrationType,
        givenDate: data.givenDate,
        nextDate: data.nextDate,
        creationDate: getCurrentDateTime()
      };

      return await addDataToCollection(dewormingsCollection, addData);
    } catch (error) {
      LogErrors(error);
      return false;
    }
  };

  const validObject = () => {
    return (formData.type !== '' &&
      formData.administrationType !== '' &&
      formData.givenDate !== '' &&
      formData.nextDate !== '');
  }

  const handleSubmit = async () => {
    if (!isUpdate) {
      const result = await populateCollection(formData, pet.id);
      if (result) {
        notifySuccess('Desparasitação adicionada com sucesso.');
        reload(pet);
      }
      else {
        notifyError('Falha ao criar a sua Desparasitação.');
      }
    }
    else {
      const result = await updateDataByDocId(dewormingsCollection, formData.id, removeId(formData));
      if (result) {
        notifySuccess('Desparasitação atualizado com sucesso.');
        reload(pet);
        closeModal();
      }
      else {
        notifyError('Falha ao atualizar o seu Desparasitação.');
        closeModal();
      }
    }
    closeModal(true);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Field
        control={Select}
        label='Tipo'
        options={typeOptions}
        placeholder='Escolha o tipo'
        name='type'
        value={formData.type}
        onChange={handleChange}
        required
      />
      <Form.Field
        control={Select}
        label='Tipo de Administração'
        options={administrationTypeOptions}
        placeholder='Escolha o tipo de administração'
        name='administrationType'
        value={formData.administrationType}
        onChange={handleChange}
        required
      />
      <Form.Input
        label='Data da Administração'
        type='date'
        name='givenDate'
        value={formData.givenDate}
        onChange={handleChange}
        required
      />
      <Form.Input
        label='Próxima Data de Administração'
        type='date'
        name='nextDate'
        value={formData.nextDate}
        onChange={handleChange}
        required
      />
      <Button type='submit' className='primary-color' disabled={!validObject()}>Gravar</Button>
    </Form>
  );
};

export default DewormingForm;
