import React from 'react';
import { Segment, Header, Image, Grid, Divider } from 'semantic-ui-react';
import photo from '../../../../resources/other/petCare.png'

const BasicCareHeader = () => (
  <Segment inverted style={{ padding: '2em 0em' }} vertical textAlign='center'>
    <Header className='step-title' as='h3' style={{ fontSize: '2em', textAlign: 'center' }}>
      Guia de Cuidados Básicos para Pets
    </Header>
    <Divider style={{ maxWidth: '80%', margin: 'auto' }} />
    <br />
    <Grid container stackable verticalAlign='middle'>
      <Grid.Row>
        <Grid.Column width={8}>
          <p style={{ fontSize: '1.33em' }}>
            Cuidar bem dos nossos animais de estimação é mais do que uma responsabilidade; é um gesto de amor e respeito que reflete a importância que eles têm nas nossas vidas. Os pets oferecem <strong className='step-title'>companhia, lealdade e conforto</strong>, e em troca, merecem um ambiente seguro, alimentação adequada, cuidados médicos regulares e atenção às suas necessidades emocionais. A atenção cuidadosa à saúde e ao bem-estar dos nossos animais pode prevenir doenças, prolongar suas vidas e melhorar significativamente a qualidade da sua existência.
          </p>
        </Grid.Column>
        <Grid.Column width={8}>
          <Image circular centered size='large' src={photo} alt="História do myPET.ID" style={{ border: '5px solid #51cb61', padding: '4px', backgroundColor: '#fff' }} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <p style={{ fontSize: '1.33em', textAlign: 'center' }}>
            Além disso, ao adotarmos práticas de cuidado responsáveis, estamos a contribuir para uma sociedade mais consciente e respeitosa com todas as formas de vida. Cuidar bem dos pets ensina valores de <strong className='step-title'>empatia e responsabilidade</strong>, especialmente às crianças, moldando cidadãos mais atenciosos e empáticos. Não se trata apenas de atender às necessidades básicas, mas de entender e respeitar a singularidade de cada animal, garantindo que eles tenham uma vida plena e feliz ao nosso lado.
          </p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
);

export default BasicCareHeader;

