import React from 'react';
import { Segment, Container, Header } from 'semantic-ui-react';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Grow from '../../../global/Grow';
import logo from '../../../../resources/new_logo_nbg.png'

const HomeBanner = () => {
  const isMobile = window.innerWidth < 758;

  return (
    <Segment inverted textAlign="center" style={{ minHeight: '50vh', padding: '1em 0em' }} vertical>
      <Container
        style={{
          backgroundImage: `url(${logo})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          textAlign: 'center',
          padding: isMobile ? '10px 2px' : '100px 20px',
        }}>
        <Header
          as='h1'
          inverted
          style={{ fontSize: '4em', fontWeight: 'normal', marginBottom: 0, marginTop: '3em' }}
        >Bem-vindo ao <strong className='step-title'>myPET.ID</strong></Header>
        <Header
          as='h2'
          content='Conecte-se ao seu pet como nunca antes.'
          inverted
          style={{ fontSize: '1.7em', fontWeight: 'normal' }}
        />
        <Grow>
          <Button size='huge' className="primary-color" as={Link} to='/about'>
            Saiba Mais
          </Button>
        </Grow>
      </Container>
    </Segment>
  );
};

export default HomeBanner;


